import React from 'react';
import DetailOverlay from '../../sharedComponents/DetailOverlay';
import {
  IOverlayDatum,
} from './otherTypes';

interface IProps {
  tooltipMap: Map<string, IOverlayDatum>;
  detailed: string | undefined;
  hideOverlay: () => void;
}

export default (props: IProps) => {
  const {
    hideOverlay, detailed, tooltipMap,
  } = props;

  if (detailed === undefined) {
    return null;
  } else {
    const retrieved = tooltipMap.get(detailed);
    if (retrieved === undefined) {
      return null;
    } else {
      const {color, title, rows} = retrieved;
      return (
        <DetailOverlay key='detail-overlay'
          rows={rows}
          color={color}
          title={title}
          hideOverlay={hideOverlay}/>
      );
    }
  }

};
