import sum from 'lodash-es/sum';
import {
  ProductClass,
  ProductLevel,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';
import {
  formatDistance,
  formatPCI,
  formatPercentage,
  formatRCA,
  formatTradeValue,
} from '../../numberFormatters';
import {
  newGetDisplayedProductCode,
  newHSColorMap,
  newSITCColorMap,
} from '../../Utils';
import {
  DisplayValue,
  DisplayValueStatus,
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';
import getTradeLabel from '../hoverTooltips/getTradeLabel';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
} from '../newChart/transformUtils';
import {
  FetchedProductDatum,
} from '../useTreeMapLocationAggregationQueries';
import {
  IOverlayDatum,
} from './otherTypes';

interface IInput {
  fetchResult: FetchedProductDatum[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  productClass: ProductClass;
  year: number;
  addExtraProductDetails: boolean;
}

const transform = (input: IInput): Map<string, IOverlayDatum> => {
  const {
    fetchResult, productClass,
    tradeDirection, tradeFlow,
    year, addExtraProductDetails,
  } = input;
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filtered = filterByMonetaryValues(withComputedTradeValues);
  const totalSum = sum(filtered.map(({monetaryValue}) => monetaryValue));

  let colorMap: Map<string, string>;
  if (productClass === ProductClass.HS) {
    colorMap = newHSColorMap;
  } else if (productClass === ProductClass.SITC) {
    colorMap = newSITCColorMap;
  } else {
    colorMap = undefined as any;
  }

  const mapPrecursor: Array<[string, IOverlayDatum]> = filtered.map(elem => {
    const {
      product: {
        id, longName, code, level,
        topLevelParent: {id: topLevelParentId},
      },
      pci,
      monetaryValue,
      rca, distance,
    } = elem;

    const codeInfo: IRow = {
      label: __lexiconText('applicationWide.code'),
      value: newGetDisplayedProductCode(code, productClass, level),
    };
    const tradeInfo: IRow = {
      label: getTradeLabel(tradeDirection, tradeFlow),
      value: formatTradeValue(monetaryValue),
    };
    const percentage = monetaryValue / totalSum;
    const shareInfo: IRow = {
      label: __lexiconText('applicationWide.share'),
      value: formatPercentage(percentage),
    };

    const yearInfo: IRow = {label: __lexiconText('applicationWide.year'), value: year};

    let pciValue: DisplayValue;
    if (level === ProductLevel.fourDigit) {
      pciValue = (pci === null) ?
                  {status: DisplayValueStatus.ShowNotAvailable} :
                  {status: DisplayValueStatus.Show, value: formatPCI(pci)};
    } else if (level === ProductLevel.section ||
                level === ProductLevel.twoDigit ||
                level === ProductLevel.sixDigit) {
      pciValue = {status: DisplayValueStatus.DoNotShow};
    } else {
      pciValue = undefined as any;
    }
    const pciInfo: IRow = {label: __lexiconText('applicationWide.pciFull'), value: pciValue};

    let rows: IRow[];
    if (addExtraProductDetails === true) {
      const rcaValue: DisplayValue = (rca === null) ?
                                        {status: DisplayValueStatus.ShowNotAvailable} :
                                        {status: DisplayValueStatus.Show, value: formatRCA(rca)};
      const distanceValue: DisplayValue = (distance === null) ?
                                        {status: DisplayValueStatus.ShowNotAvailable} :
                                        {status: DisplayValueStatus.Show, value: formatDistance(distance)};

      const rcaInfo: IRow = {label: __lexiconText('applicationWide.rcaFull'), value: rcaValue};
      const distanceInfo: IRow = {label: __lexiconText('applicationWide.distance'), value: distanceValue};
      rows = [codeInfo, yearInfo, tradeInfo, shareInfo, rcaInfo, distanceInfo, pciInfo];
    } else {
      rows = [codeInfo, yearInfo, tradeInfo, shareInfo, pciInfo];
    }

    const color = colorMap.get(topLevelParentId);
    if (color === undefined) {
      throw new Error('Cannot retrieve color for section ' + topLevelParentId);
    }
    const overlayDatum: IOverlayDatum = {
      id,
      title: longName,
      rows,
      color,
    };
    return [id, overlayDatum] as [string, IOverlayDatum];
  });

  return new Map(mapPrecursor);
};

export default transform;
