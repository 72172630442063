import React from 'react';
import { render } from 'react-dom';
import {
  TradeDirection,
  TradeFlow,
  TreeMapInput,
  LocationLevel
} from '../../graphQL/types/shared';
import useLastSuccesfulFetch from '../newChart/useLastSuccesfulFetch';
import {
  Result,
  SuccessResponse,
  useCombinedQuery,
} from '../useTreeMapLocationAggregationQueries';
import DetailOverlayContainer from './DetailOverlayContainer';
import transform from './transform';

interface IRenderPropInput {
  hideOverlay: () => void;
  detailed: string | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  year: number;
}

interface IProps {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const combineNewIntoOldRenderPropInput =
  (oldInput: IRenderPropInput, newInput: IRenderPropInput): IRenderPropInput => {

  const {
    hideOverlay: _unused1,
    detailed: _unused2,
    ...restOfOldInput
  } = oldInput;
  const out: IRenderPropInput = {
    ...restOfOldInput,
    hideOverlay: newInput.hideOverlay,
    detailed: newInput.detailed,
  };
  return out;
};

const isFetchedDataEmpty = ({treeMap}: SuccessResponse) => (treeMap.length === 0);

const Wrapper = (props: IProps) => {
  const lastSuccesfulFetch = useLastSuccesfulFetch<SuccessResponse, TreeMapInput, IRenderPropInput>({
    isFetchedDataEmpty,
    inputs: {
      queryResult: props.queryResult,
      extraInputs: props.renderPropInput,
    },
  });

  const {
    renderPropInput,
    queryResult: {loading, error, data, variables},
  } = props;
  const { dataForTooltips, locationLevel} = renderPropInput;
  const { tooltipData } = dataForTooltips;


  let output: React.ReactElement<any> | null;
  if (loading === true) {
    if (lastSuccesfulFetch === undefined) {
      output = null;
    } else {
      const combinedRenderPropInput = combineNewIntoOldRenderPropInput(
        lastSuccesfulFetch.extraInputs, renderPropInput,
      );
      const {tradeDirection, tradeFlow, hideOverlay, detailed} = combinedRenderPropInput;
      const transformed = transform({
        fetchResult: lastSuccesfulFetch.data,
        // dataForTooltips,
        locationLevel,
        type: lastSuccesfulFetch.variables.facet,
        productClass: lastSuccesfulFetch.variables.productClass,
        year: lastSuccesfulFetch.extraInputs.year,
        tradeDirection, tradeFlow,
      });
      output = (
        <DetailOverlayContainer
          tooltipMap={transformed}
          detailed={detailed}
          hideOverlay={hideOverlay}
        />
      );
    }
  } else if (error !== undefined) {
    output = null;
    console.error(error);
  } else if (data !== undefined) {

    // let groupedHoverTooltips = locationLevel === LocationLevel.country ? tooltipData : transformDataWithNewTopLevelParent(tooltipData, locationLevel, regions, subregions);

    const transformed = transform({
      // fetchResult: data.treeMap,
      fetchResult: data,
      // dataForTooltips,
      locationLevel,
      type: variables.facet,
      productClass: variables.productClass,
      year: renderPropInput.year,
      tradeDirection: renderPropInput.tradeDirection,
      tradeFlow: renderPropInput.tradeFlow,
    });
    output = (
      <DetailOverlayContainer
        tooltipMap={transformed}
        detailed={renderPropInput.detailed}
        hideOverlay={renderPropInput.hideOverlay}
      />
    );
  } else {
    output = null;
  }
  return output;
};

const getRenderProp = (input: IRenderPropInput) => (queryResult: Result) => (
  <Wrapper renderPropInput={input} queryResult={queryResult}/>
);

interface Props {
  input: IRenderPropInput;
  variables: TreeMapInput;
}

const RenderProp = (props: Props) => {
  const {
    input, variables,
  } = props;
  const chartRenderProp = getRenderProp(input);
  const result = useCombinedQuery(variables);
  return (<>{chartRenderProp(result as any)}</>);
};

export default RenderProp;
