import { Location, Product, TreeMapInput, TreeMapProduct } from '../graphQL/types/shared';
import { gql, useQuery } from '../graphQL/useQuery';

//#region GraphQL-related
export const combinedQuery = gql`
  query TreeMapCombined(
    $facet: TreeMapType!,
    $productClass: ProductClass,
    $year: Int,
    $productLevel: ProductLevel,
    $location: ID,
    $product: ID,
    $partner: ID,
  ) {
    treeMap(
      facet: $facet,
      productClass: $productClass,
      year: $year,
      productLevel: $productLevel,
      location: $location,
      product: $product,
      partner: $partner,
      mergePci: true,
    ) {
      ... on TreeMapProduct {
        product {
          id
          shortName
          longName
          code
          type: productType
          topLevelParent {
            id
            shortName
          }
          level
        }
        exportValue
        importValue
        pci
        rca
        distance
      }
      ... on TreeMapLocation {
        location {
          id
          shortName
          longName
          code
          topLevelParent {
            id
            shortName
          }
          level
        }
        exportValue
        importValue
      }
    }

    regions: allGroups(groupType: region) {
      id
      groupType
      groupName
      parent{
        id
        groupType
        groupName
      }
      members{
        id
        shortName
      }
    }

    subregions: allGroups(groupType: subregion) {
      id
      groupType
      groupName
      parent{
        id
        groupType
        groupName
      }
      members{
        id
        shortName
      }
    }
  }
`;




export interface FetchedProductDatum {
  exportValue: TreeMapProduct['exportValue'];
  importValue: TreeMapProduct['importValue'];
  pci: TreeMapProduct['pci'];
  rca: TreeMapProduct['rca'];
  distance: TreeMapProduct['distance'];
  product: {
    id: Product['id']
    code: Product['code']
    shortName: Product['shortName']
    longName: Product['longName']
    topLevelParent: {id: Product['topLevelParent']['id'], shortName: Product['topLevelParent']['shortName']}
    type: Product['productType']
    level: Product['level'],
  };
}

export interface FetchedLocationDatum {
  exportValue: number | null;
  importValue: number | null;
  location: {
    id: Location['id']
    shortName: Location['shortName']
    longName: Location['longName']
    code: Location['code']
    level: Location['locationLevel']
    topLevelParent: {id: Location['topLevelParent']['id'], shortName: Location['topLevelParent']['shortName']},
  };
}

export interface SuccessResponse {
  treeMap: FetchedLocationDatum[] | FetchedProductDatum[];
}

export interface Result {
  loading: boolean;
  error?: any;
  data: undefined | SuccessResponse;
  variables: TreeMapInput;
  [key: string]: any;
}

export const useCombinedQuery = (variables: TreeMapInput) => {
  const result = useQuery<SuccessResponse, TreeMapInput>(combinedQuery, {variables});
  return result;
};
