import {
  LocationLevel,
  TradeDirection,
  TradeFlow,
  TreeMapType,
} from '../../graphQL/types/shared';
import { TreeMapInput } from '../../graphQL/types/shared';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
  SuccessResponse,
} from '../useTreeMapLocationAggregationQueries';
import {
  ITreeMapCell,
  TreeMapCSVProductRow,
  TreeMapCSVRow,
} from './otherTypes';
import transformLocation from './transformLocations';
import transformProduct from './transformProducts';
import { groups, rollups, sum, index } from "d3-array";
import { transformDataWithNewTopLevelParent } from "../Utils";

export interface IOtherInputs {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

export interface ITransformInput {
  fetchedData: SuccessResponse['treeMap'];
  variables: TreeMapInput;
  otherInputs: IOtherInputs;
}

const transform = (transformInput: ITransformInput):
  {transformed: ITreeMapCell[], csvData: TreeMapCSVRow[] | TreeMapCSVProductRow[]} => {
  const {
    fetchedData, variables, otherInputs, regions, subregions, setDataForTooltips
  } = transformInput;

  const {
    width, height, selectedCategories, tradeDirection, tradeFlow,
  } = otherInputs;
  let transformed: ITreeMapCell[];
  let csvData: TreeMapCSVRow[] | TreeMapCSVProductRow[];
  const treeMapInput = variables;

  if (treeMapInput.facet === TreeMapType.CPY_C ||
        treeMapInput.facet === TreeMapType.CCPY_CC) {
    if (treeMapInput.productClass === null) {
      throw new Error('Product class cannot be null in CPY_C or CCPY_CC');
    }

        /* AGGREGATION HAPPENS HERE */

    /*
    Aggregating import and export value by product;
    this assumes that each country in group returns
    own copy of object of form:

    {
    "__typename": "TreeMapProduct",
    "product": {
        "__typename": "Product",
        "id": "product-HS-650",
        "shortName": "Horses",
        "longName": "Live horses, asses, mules or hinnies",
        "code": "0101",
        "type": "Goods",
        "topLevelParent": {
            "__typename": "Product",
            "id": "product-HS-1",
            "shortName": "Agriculture"
        },
        "level": "fourDigit"
    },
    "exportValue": 247079,
    "importValue": 15892480,
    "pci": 0.021367,
    "rca": 0.002467,
    "distance": 0.818353
    }

    If there are N countries in a group, then there will be 
    N objects (with the above structure) for the same product.id
    */



    const productMetadata = index(fetchedData, d => d.product.id);
    const groupedResultData = groups(fetchedData, d => d.product.id)
      .map(d => ({id: d[0], data: d[1]}));

    const rolledUp = rollups(fetchedData, d => {
      return {
        importValue: sum(d, s => s.importValue),
        exportValue: sum(d, s => s.exportValue),
        // How aggregate PCI, RCA, and distance values?
        pci: d[0].pci,
        rca: d[0].rca,
        distance: d[0].distance
      }
    }, d => d.product.id)
    .map(d => ({
      // Need to restore __typename: 'TreeMapProduct'
      exportValue: d[1].exportValue,
      importValue: d[1].importValue,
      pci: d[1].pci,
      rca: d[1].rca,
      distance: d[1].distance,
      product: productMetadata.get(d[0]).product
    }));

    const transformedData = transformProduct({
      fetchResult: rolledUp as FetchedProductDatum[],
      width, height, selectedCategories,
      tradeDirection, tradeFlow,
      productClass: treeMapInput.productClass,
    });
    transformed = transformedData.transformed;
    csvData = transformedData.csvData;


  } else if (treeMapInput.facet === TreeMapType.CPY_P ||
              treeMapInput.facet === TreeMapType.CCY_C ||
              treeMapInput.facet === TreeMapType.CCPY_CP) {

    const selectedLevel = variables.locationLevel;


    /*
    Aggregating import and export value by country;
    this assumes that each product in group returns
    own copy of object of form:

{
    "__typename": "TreeMapLocation",
    "location": {
        "__typename": "Location",
        "id": "location-16",
        "shortName": "Azerbaijan",
        "longName": "Azerbaijan",
        "code": "AZE",
        "topLevelParent": {
            "__typename": "Location",
            "id": "location-353",
            "shortName": "Asia"
        },
        "level": "country"
    },
    "exportValue": 2620595064,
    "importValue": 5461255360
}

    Depending on detail level selected for product: 
      - country: No change to data
      - subregion: Sum import and export data based on subregion parent
      - region: Sum import and export data based on region parent

      Need to return:
{
    "__typename": "TreeMapLocation",
      "location": {
        "__typename": "Location",
        "id": "group-28",
        "shortName": "Western Asia",
        "longName": "Western Asia",
        "code": "",
        "topLevelParent": {
            "__typename": "Location",
            "id": "group-3",
            "shortName": "Asia"
        },
        "level": "subregion"
    },
    "exportValue": ###,
    "importValue": ###
    */

      let groupedData = transformDataWithNewTopLevelParent(fetchedData, selectedLevel, regions, subregions);

      if(setDataForTooltips) {
        setDataForTooltips({tooltipData: groupedData, regions, subregions});
      }


    const transformedData = transformLocation({
      // fetchResult: fetchedData as FetchedLocationDatum[],
      fetchResult: groupedData as FetchedLocationDatum[],
      width, height, selectedCategories,
      tradeDirection, tradeFlow,
    });
    transformed = transformedData.transformed;
    csvData = transformedData.csvData;

  } else {
    // The following lines will never be executed:
    transformed = undefined as any;
    csvData = undefined as any;
  }
  return {transformed, csvData};
};

export default transform;
