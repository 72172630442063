import styled, {
  css,
} from 'styled-components';
import {
  CategorySelectorContainer,
  vizGridZIndices,
} from '../../viz/VizGrid';

export const Divider = styled.div`
  height: 50%;
  width: 1px;
  background-color: rgb(179, 179, 179);
`;

export const categoryIconsContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SelectorContainer = styled(CategorySelectorContainer)`
  ${categoryIconsContainerStyle}
  z-index: ${vizGridZIndices.categorySelector};

  @media (max-width: 1160px) {
    justify-content: flex-start;
  }
`;
