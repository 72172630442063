import sum from 'lodash-es/sum';
import {
  formatPercentage,
  formatTradeValue,
} from '../../numberFormatters';
import {
  newRegionColorMap,
  groupedRegionColorMap
} from '../../Utils';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';
import getTradeLabel from '../hoverTooltips/getTradeLabel';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
} from '../newChart/transformUtils';
import {
  FetchedLocationDatum,
} from '../useTreeMapLocationAggregationQueries';
import {
  IOverlayDatum,
} from './otherTypes';

import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';

interface IInput {
  fetchResult: FetchedLocationDatum[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  year: number;
}

const transform = (input: IInput): Map<string, IOverlayDatum> => {
  const {
    fetchResult, tradeDirection, tradeFlow, year,
  } = input;


  // const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);


  const filtered = filterByMonetaryValues(withComputedTradeValues);
  const totalSum = sum(filtered.map(({monetaryValue}) => monetaryValue));

  const mapPrecursor: Array<[string, IOverlayDatum]> = filtered.map(elem => {
    const {
      location: {
        id, longName,
        topLevelParent: {id: topLevelParentId},
      },
      monetaryValue,
    } = elem;

    const yearInfo: IRow = {label: __lexiconText('applicationWide.year'), value: year};
    const tradeInfo: IRow = {
      label: getTradeLabel(tradeDirection, tradeFlow),
      value: formatTradeValue(monetaryValue),
    };
    const percentage = monetaryValue / totalSum;
    const shareInfo: IRow = {
      label: __lexiconText('applicationWide.share'),
      value: formatPercentage(percentage),
    };

    const rows = [yearInfo, tradeInfo, shareInfo];

    // const color = newRegionColorMap.get(topLevelParentId);
    const color = groupedRegionColorMap.get(topLevelParentId);

    if (color === undefined) {
      throw new Error('Cannot retrieve color for region ' + topLevelParentId);
    }

    const overlayDatum: IOverlayDatum = {
      id,
      title: longName,
      rows,
      color,
    };
    return [id, overlayDatum] as [string, IOverlayDatum];
  });

  return new Map(mapPrecursor);
};

export default transform;
