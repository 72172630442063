import React from 'react';
import styled from 'styled-components';
import { gridSmallMediaSize } from '../../countryProfiles/Grid';

const size = '30px';
const sizeSmallScreen = '18px';
export const ImageOuterContainer = styled.div`
  --fill: black;

  width: ${size};
  height: ${size};

  @media (max-width: ${gridSmallMediaSize}px) {
    width: ${sizeSmallScreen};
    height: ${sizeSmallScreen};
  }
`;

export const ImageInnerContainer = styled.div`
  position: relative;
  width: 85%;
  margin: 0 auto;
  height: 0;
  padding-top: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  path,
  polygon,
  rect {
    fill: var(--fill);
  }

  @media (max-width: ${gridSmallMediaSize}px) {
    width: 100%;
  }
`;

interface IProps {
  id: string;
  color: string;
}

export default (props: IProps) => {
  const {id, color} = props;
  const svgString = require('../../img/icons/categories_new/' + id + '.svg');
  return (
    <ImageOuterContainer>
      <ImageInnerContainer
        dangerouslySetInnerHTML={{__html: svgString}}
        style={{'--fill': color}}
      />
    </ImageOuterContainer>
  );
};
