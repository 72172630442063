import sum from 'lodash-es/sum';
import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';
import { formatPercentage } from '../../numberFormatters';
import {
  measuredCharacterHeight,
  measuredCharacterWidth,
  newRegionColorMap,
  groupedRegionColorMap,
  referenceFontSize,
} from '../../Utils';
import {
  FetchedLocationDatum,
} from '../useTreeMapLocationAggregationQueries';
import addTextLayout from './addTextLayout';
import {
  ITreeMapCell,
  TreeMapCSVRow,
} from './otherTypes';
import performLayout from './performLayout';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  maxCharacterHeightAtMinFontSize,
} from './transformUtils';

interface IInput {
  fetchResult: FetchedLocationDatum[];
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

export interface ITransformed {
  id: string;
  label: string;
  monetaryValue: number;
  topLevelParentId: string;
  percentage: number;
}

const transform = (input: IInput): {transformed: ITreeMapCell[], csvData: TreeMapCSVRow[]} => {

  const {fetchResult, width, height, selectedCategories, tradeDirection, tradeFlow} = input;


  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filteredByMonetaryValues = filterByMonetaryValues(withComputedTradeValues);
  const selectedCategoriesSet = new Set(selectedCategories);
  const filteredByCategories = filteredByMonetaryValues.filter(
    elem => selectedCategoriesSet.has(elem.location.topLevelParent.id),
  );
  const totalSum = sum(filteredByCategories.map(({monetaryValue}) => monetaryValue));

  const csvData: TreeMapCSVRow[] = [];
  const transformed: ITransformed[] = filteredByCategories.map(elem => {
    const {
      monetaryValue,
      location: {id, shortName,
        topLevelParent: {id: topLevelParentId},
      },
    } = elem;
    const exportImportKey = tradeDirection === TradeDirection.export ? 'Gross Export' : 'Gross Import';
    csvData.push({
      Name: shortName,
      [exportImportKey]: monetaryValue,
      Share: (monetaryValue / totalSum) * 100,
    });
    return {
      id, monetaryValue, topLevelParentId,
      label: shortName,
      percentage: monetaryValue / totalSum,
    };
  });

  
  const withCellLayout = performLayout(transformed, {x0: 0, y0: 0, x1: width, y1: height});
  const withTextLayout = withCellLayout.map(elem => addTextLayout({
    datum: elem,
    referenceFontSize, measuredCharacterHeight,
    measuredCharacterWidth, maxCharacterHeightAtMinFontSize,
    cellLabel: elem.label,
    cellValue: formatPercentage(elem.percentage),
  }));

  const withColor: ITreeMapCell[] = withTextLayout.map(({topLevelParentId, monetaryValue, ...rest}) => {
    const color = newRegionColorMap.get(topLevelParentId) || groupedRegionColorMap.get(topLevelParentId);
    if (color === undefined) {
      throw new Error('Cannot find color for top region ' + topLevelParentId);
    }
    const out: ITreeMapCell = {
      ...rest,
      color,
      value: monetaryValue,
    };
    return out;
  });
  return {transformed: withColor, csvData};
};

export default transform;
