import React from 'react';
import {
  ProductClass,
  ProductLevel,
} from '../../graphQL/types/shared';
import {gql, useQuery} from '../../graphQL/useQuery';
import {
  FetchedProductDatum,
} from './graphQLTypes';
import Selector from './ProductCategorySelector';
import {
  SelectorContainer,
} from './sharedStyling';

interface Variables {
  productClass: ProductClass;
  productLevel: ProductLevel | null;
}

const query = gql`
  query ProductCategorySelector($productClass: ProductClass!, $productLevel: ProductLevel!) {
    productCategorySelector: allProducts(productClass: $productClass, productLevel: $productLevel) {
      id
      productType
      shortName
    }
  }
`;

interface SuccessResponse {
  productCategorySelector: FetchedProductDatum[];
}

interface IProps {
  productClass: ProductClass;
  selectedCategories: string[];
  isServicesEnabled: boolean;
  isServicesNotAvailableForAllYears: boolean;
  hasServicesNotAvailableForSomeYearsTooltipBeenShown: boolean;
  setSelected: (ids: string[]) => void;
  Container: React.ComponentType<any>;
  extraDOMAttrOnRootNode: object | undefined;
}

export default (props: IProps) => {
  const {
    productClass, selectedCategories, setSelected,
    isServicesEnabled, isServicesNotAvailableForAllYears,
    hasServicesNotAvailableForSomeYearsTooltipBeenShown,
  } = props;
  const variables: Variables = {productClass, productLevel: ProductLevel.section};
  const {loading, error, data} = useQuery<SuccessResponse, Variables>(query, {variables});
  if (productClass === null) {
    throw new Error('Product class cannot be null in product class selector');
  }
  if (loading === true) {
    return null;
  } else if (error !== undefined) {
    console.error(error);
    return null;
  } else if (data !== undefined) {
    return(
      <SelectorContainer>
        <Selector
          allCategories={data.productCategorySelector}
          selectedCategories={selectedCategories}
          allowSectorToggle={true}
          setSelected={setSelected}
          productClass={productClass}
          isServicesEnabled={isServicesEnabled}
          isServicesNotAvailableForAllYears={isServicesNotAvailableForAllYears}
          hasServicesNotAvailableForSomeYearsTooltipBeenShown={hasServicesNotAvailableForSomeYearsTooltipBeenShown}
          servicesDisabledMessage={{useAlternateMessage: false}}
        />
      </SelectorContainer>
    );
  } else {
    return null;
  }
};
