import React, {
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { QueryLevel } from '../graphQL/types/shared';
import { useQueryParams } from '../routing';
import RadioSelector from '../sharedComponents/radioSelector';
import {
  scrollToTop,
  Target,
} from '../Utils';
import Controls , {
  TypeOfAggregationEntity,
  ITargetDropdownState,
  ITargetState,
  ITradeDirectionState,
  TargetDropdownType,
} from '../viz/controls';
import {
  parseQueryParamsIntoQueryStore, useVizTypeChanger,
} from '../viz/routingUtils';
import {
  productClassChoices,
  SetActivePanelProps as Props,
  tradeFlowChoices,
  VizType,
} from '../viz/Utils';
import { vizSettingsPortalId } from '../viz/VizSettings';
import treeURLTransformers from './URLTransformers';
import useNavigation from './useNavigation';

const currentVizType = VizType.Tree;

const TreeStatic = ({activePanel, setActivePanel, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled, setDisplayIntraRegionTradeDisclaimer, setHighlighted, highlighted}: Props) => {
  useEffect(scrollToTop, []);
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(treeURLTransformers, queryParams);
  const {productClass, tradeDirection, tradeFlow} = queryStore;

  const {
    changeCountry, changeLocation, changeTradeDirection,
    changeProductClass,
    changeTradeFlow, changeProduct, changeTarget, changePartner,
    resetCountryAndTarget,
  } = useNavigation();

  const tradeDirectionPropsForVizControls: ITradeDirectionState = {
    isVisible: true,
    value: tradeDirection,
    onTradeDirectionChange: changeTradeDirection,
  };
  let targetProps: ITargetState;
  if (queryStore.target === Target.Partner) {
    targetProps = {
      isVisible: true,
      target: Target.Partner,
      onTargetChange: (p) => { setHighlighted(undefined); changeTarget(p); },
    };
  } else {
    targetProps = {
      isVisible: true,
      target: Target.Product,
      // onTargetChange: changeTarget,
      onTargetChange: (p) => { setHighlighted(undefined); changeTarget(p); }
    };
  }

  const targetDropdownState: ITargetDropdownState = {
    isVisible: true,
    type: queryStore.target === Target.Product ? TargetDropdownType.Product : TargetDropdownType.Partner,
    product: queryStore.product,
    productClass: queryStore.productClass,
    // onProductChange: changeProduct,
    onProductChange: (p) => { setHighlighted(undefined); changeProduct(p)},
    partner: queryStore.partner,
    // onPartnerChange: changePartner,
    onPartnerChange: (p) => { setHighlighted(undefined); changePartner(p)},

  };

  let guidanceText: string;
  if (queryStore.target === Target.Product) {
    guidanceText = __lexiconText('guidanceText.productMode');
  } else {
    guidanceText = __lexiconText('guidanceText.partnerMode');
  }

  //#region Trade flow selector:
  const tradeFlowSelector = (
    <RadioSelector
      tooltipText={__lexiconText('applicationWide.tradeFlowSelector.tooltipText')}
      mainLabel={__lexiconText('applicationWide.tradeFlowSelector.mainLabel')}
      choices={tradeFlowChoices}
      selected={tradeFlow}
      onClick={changeTradeFlow}
    />
  );
  //#endregion
  //#region Product class selector
  const productClassSelector = (
    <RadioSelector
      tooltipText={__lexiconText('applicationWide.productClassSelector.tooltipText')}
      mainLabel={__lexiconText('applicationWide.productClassSelector.mainLabel')}
      choices={productClassChoices}
      selected={productClass}
      onClick={(p) => { setHighlighted(undefined); changeProductClass(p); }}
    />
  );
  //#endregion

  const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

  let vizSettings: React.ReactElement<any> | null;
  if (vizSettingsNodeRef) {
    vizSettings = ReactDOM.createPortal((
      <>
        {productClassSelector}
        {tradeFlowSelector}
      </>
    ), vizSettingsNodeRef);
  } else {
    vizSettings = null;
  }

  const initialTypeOfAggregationEntity = queryStore.country === undefined && queryStore.product !== undefined
    ? TypeOfAggregationEntity.product : TypeOfAggregationEntity.location;

  const changeVizType = useVizTypeChanger();



  //#region render
  return (
    <>
      <Controls
        vizType={currentVizType}
        country={queryStore.country} 
        queryLevel={queryStore.queryLevel}
        tradeDirectionProps={tradeDirectionPropsForVizControls}
        targetProps={targetProps} targetDropdownState={targetDropdownState} guidanceText={guidanceText}
        onCountryChange={changeCountry}
        onLocationChange={changeLocation}
        onVizTypeChange={changeVizType}
        globalProductClass={productClass}
        globalProduct={queryStore.product}
        globalOnProductChange={changeProduct}
        initialTypeOfAggregationEntity={initialTypeOfAggregationEntity}
        resetCountryAndTarget={resetCountryAndTarget}
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}
        setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
        setHighlighted={setHighlighted}
        highlighted={highlighted}
      />
      {vizSettings}
    </>
  );
  //#endregion
};

export default TreeStatic;
