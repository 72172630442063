import React from 'react';
import {
  LocationLevel,
} from '../../graphQL/types/shared';
import {gql, useQuery} from '../../graphQL/useQuery';
import {
  FetchedLocationDatum,
} from './graphQLTypes';
import Selector from './RegionSelector';

interface Variables {
  locationLevel: LocationLevel;
}

const query = gql`
  query RegionSelector($locationLevel: LocationLevel!) {
    allRegions: allLocations(level: $locationLevel) {
      id
      shortName
    }
  }
`;

const newGroupsQuery = gql`
  query RegionSelector {
    allRegions: allGroups(groupType: region) {
      id
      groupName
    }
  }
`

interface SuccessResponse {
  allRegions: FetchedLocationDatum[];
}

interface IProps {
  selectedCategories: string[];
  setSelected: (ids: string[]) => void;
}

const Region = (props: IProps) => {
  const {
    selectedCategories, setSelected,
  } = props;
  const variables: Variables = {
    locationLevel: LocationLevel.region,
  };
  // const {loading, error, data} = useQuery<SuccessResponse, Variables>(query, {variables});
  const {loading, error, data} = useQuery<SuccessResponse, Variables>(newGroupsQuery, {variables});

  if (loading === true) {
    return null;
  } else if (error !== undefined) {
    console.error(error);
    return null;
  } else if (data !== undefined) {
    return (
      <Selector
        allCategories={data.allRegions}
        selectedCategories={selectedCategories}
        setSelected={setSelected}
      />
    );
  } else {
    return null;
  }
};

export default Region;
