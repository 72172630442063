import React from 'react';
import {
  TradeDirection,
  TradeFlow,
  TreeMapInput,
  TreeMapType,
  LocationLevel
} from '../../graphQL/types/shared';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
  Result,
  useCombinedQuery,
} from '../useTreeMapLocationAggregationQueries';
import {
  ITooltipDatum,
} from './otherTypes';
import TooltipsContainer from './TooltipsContainer';
import transformLocations from './transformLocations';
import transformProducts from './transformProducts';
import { transformDataWithNewTopLevelParent } from "../Utils";

interface IRenderPropInput {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  hovered: string | undefined;
  highlighted: string | undefined;
  clearHighlightTooltip: () => void;
}

interface IProps {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const HoverTooltips = (props: IProps) => {
  const {
    renderPropInput: {
      width, height, selectedCategories, tradeDirection, tradeFlow,
      hovered, highlighted, clearHighlightTooltip, locationLevel
      
    },
    queryResult: {loading, error, data, variables},
  } = props;
  // const {tooltipData: hoverTooltips, regions, subregions} = dataForTooltips;


  let output: React.ReactElement<any> | null;

  if (loading === true) {
    output = null;
  } else if (error !== undefined) {
    console.error(error);
    output = null;
  } else if (data !== undefined) {

    const {facet: type, productClass} = variables;
    // const regions = data.regions;
    // const subregions = data.subregions;

    // const hoverTooltips = dataForTooltips;
    let transformed: Map<string, ITooltipDatum>;
    if (type === TreeMapType.CPY_C || type === TreeMapType.CCPY_CC) {
      if (productClass === null) {
        throw new Error('Product class cannot be null in CPY_C or CCPY_CC');
      }


      transformed = transformProducts({
        fetchResult: data.treeMap as FetchedProductDatum[],
        width, height, selectedCategories,
        tradeDirection, tradeFlow,
        productClass,
      });
    } else if (type === TreeMapType.CCY_C ||
                type === TreeMapType.CCPY_CP ||
                type === TreeMapType.CPY_P) {

      let groupedData = transformDataWithNewTopLevelParent(data.treeMap, locationLevel, data.regions, data.subregions);
      transformed = transformLocations({
        // fetchResult: fetchedData as FetchedLocationDatum[],
        fetchResult: groupedData as FetchedLocationDatum[],
        width, height, selectedCategories,
        tradeDirection, tradeFlow, locationLevel
      });
      // if(hoverTooltips) {
      //   let groupedHoverTooltips = locationLevel === LocationLevel.country ? hoverTooltips : transformDataWithNewTopLevelParent(hoverTooltips, locationLevel, regions, subregions);


        // transformed = transformLocations({
        //   fetchResult: groupedHoverTooltips as FetchedLocationDatum[],
        //   width, height, selectedCategories,
        //   tradeDirection, tradeFlow, locationLevel
        // });

  
      // }
    } else {
      console.error('Invalid tree map type');
      // The following lines will never be executed:
      transformed = undefined as any;
    }




    output = (
      <TooltipsContainer
        hovered={hovered}
        highlighted={highlighted}
        tooltipMap={transformed}
        clearHighlightTooltip={clearHighlightTooltip}
      />
    );
  } else {
    output = null;
  }
  return output;
};

const getRenderProp =
  (input: IRenderPropInput) =>
    (queryResult: Result) => (
      <HoverTooltips renderPropInput={input} queryResult={queryResult}/>
    );

interface Props {
  input: IRenderPropInput;
  variables: TreeMapInput;
}

const RenderProp = (props: Props) => {
  const {
    input, variables,
  } = props;
  const chartRenderProp = getRenderProp(input);
  const result = useCombinedQuery(variables);
  return (<>{chartRenderProp(result as any)}</>);
};

export default RenderProp;
