import {
  ProductClass,
  ProductType,
} from '../graphQL/types/shared';
import {
  TreeMapType,
} from '../graphQL/types/shared';
import {
  extractFromStringProductId,
} from '../graphQL/Utils';
import {
  DataIssueType,
} from '../sharedComponents/newDataNotes/types';
import {
  failIfValidOrNonExhaustive,
} from '../Utils';
import {
  ISuccessResponse,
} from './index';

type IInput = {
  type: TreeMapType
  product: string | null
  location: string | null
  productClass: ProductClass | null
  locations: ISuccessResponse['treeIndexLocations'];
  hsProducts: ISuccessResponse['treeIndexHSProducts'];
  sitcProducts: ISuccessResponse['treeIndexSITCProducts'];
};

export default (input: IInput) => {
  const {
    type, product, location,
    locations, hsProducts, sitcProducts,
  } = input;


  let showCountryNotes: boolean;
  if (location !== null) {
    let foundLocation = locations.find(elem => elem.id === location);
    if (foundLocation === undefined) {
      throw new Error('Cannot find location for ID ' + location);
    }
    showCountryNotes = (foundLocation.isDataTrustworthy === false);
    
  } else {
    showCountryNotes = false;
  }


  let showServicesNotes: boolean;
  if (type === TreeMapType.CCY_C) {
    showServicesNotes = true;
  } else if (type === TreeMapType.CCPY_CC) {
    showServicesNotes = true;
  } else if (type === TreeMapType.CPY_P) {
    if (product === null) {
      throw new Error('Product must be defined in CPY_P');
    }
    const {productClass} = extractFromStringProductId(product);
    let fetchedProducts: typeof hsProducts;
    if (productClass === ProductClass.HS) {
      fetchedProducts = hsProducts;
    } else if (productClass === ProductClass.SITC) {
      fetchedProducts = sitcProducts;
    } else if (productClass === null) {
      throw new Error('Product class cannot be null in CPY_P');
    } else {
      failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
      // The following lines will never be executed:
      fetchedProducts = [];
    }
    const foundProduct = fetchedProducts.find(elem => elem.id === product);
    if (foundProduct === undefined) {
      throw new Error('Cannot find product data for ID ' + product);
    }
    showServicesNotes = (foundProduct.topLevelParent.type === ProductType.Service);
  } else if (type === TreeMapType.CPY_C) {
    if (location === null) {
      throw new Error('Location must be defined in CPY_C');
    }
    const foundLocation = locations.find(elem => elem.id === location);
    if (foundLocation === undefined) {
      throw new Error('Cannot find location for ID ' + location);
    }
    showServicesNotes = !foundLocation.hasReportedServicesInAnyYear ||
                          !foundLocation.hasReportedServicesLastYear;
  } else if (type === TreeMapType.CCPY_CP) {
    showServicesNotes = false;
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
    // The following lines will never be executed:
    showServicesNotes = false;
  }

  let isDataNotesWarningActive: boolean;
  const dataNotes: DataIssueType[] = [];
  if (showCountryNotes === true || showServicesNotes === true) {
    if (showCountryNotes) {
      dataNotes.push(DataIssueType.UnreliableCountry);
    }
    if (showServicesNotes) {
      dataNotes.push(DataIssueType.ServicesInGeneral);
    }
    isDataNotesWarningActive = true;
  } else {
    isDataNotesWarningActive = false;
  }


  return {
    isDataNotesWarningActive, dataNotes,
    showCountryNotes, showServicesNotes,
  };

};
