import {
  TradeDirection,
  TradeFlow,
  TreeMapInput,
} from '../../graphQL/types/shared';
import {
  pdfPromiseWorker,
} from '../../sharedComponents/exports/mainThreadPDFWorkerInstance';
import {
  svgPromiseWorker,
} from '../../sharedComponents/exports/mainThreadSVGWorkerInstance';
import {
  PDFWorkerMessage,
  PDFWorkerMessageType,
  SVGWorkerMessage,
  SVGWorkerMessageType,
} from '../../sharedComponents/exports/Utils';
import getPNGDataURLGetter from '../../sharedComponents/getPNGDataURL';
import {
  GraphExportType,
} from '../../Utils';
import graphTotalTransform from '../graphTotal/transform';
import chartTransform from '../newChart/transform';
import {
  SuccessResponse,
} from '../useTreeMapLocationAggregationQueries';
import {
  GraphData,
} from './types';

interface IInput {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

export const getFetchedDataToGraphData = (input: IInput) => (
    fetchedData: SuccessResponse,
    variables: TreeMapInput,
  ): GraphData => {

  const {treeMap, regions, subregions} = fetchedData;
  const {
    width, height, selectedCategories, tradeDirection, tradeFlow,
  } = input;


  const {transformed: treeMapCells} = chartTransform({
    fetchedData: treeMap,
    regions,
    subregions,
    variables,
    otherInputs: {width, height, selectedCategories, tradeDirection, tradeFlow},
  });



  const {numerator: total} = graphTotalTransform({
    fetchResult: treeMap,
    variables,
    otherInputs: {selectedCategories, tradeDirection, tradeFlow},
  });


  return {
    treeMapCells, total, width, height,
  };
};

export const graphDataToSVG = async (graphData: GraphData) => {
  const message: SVGWorkerMessage = {
    type: SVGWorkerMessageType.TREE_EXPORT_SVG,
    content: {
      ...graphData,
      exportType: GraphExportType.SVG,
    },
  };
  const svgString = await svgPromiseWorker.postMessage(message);
  return svgString;
};

export const graphDataToPNG = getPNGDataURLGetter<GraphData>(graphDataToSVG);

export const graphDataToPDF = async (graphData: GraphData) => {
  const message: PDFWorkerMessage = {
    type: PDFWorkerMessageType.TREE_EXPORT_PDF,
    content: {
      ...graphData,
      exportType: GraphExportType.PDF,
    },
  };
  const blob = await pdfPromiseWorker.postMessage(message);
  return blob;
};
