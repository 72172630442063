import React, {useState} from 'react';
import {
  Location,
  Product,
  ProductClass,
} from '../graphQL/types/shared';
import { gql, useQuery } from '../graphQL/useQuery';
import Loading from '../sharedComponents/GraphLoading';
import { SharePlaceholder, TutorialModalProps } from '../sharedComponents/newGraphShare';
import { SetActivePanelProps } from '../viz/Utils';
import { ChartContainer } from '../viz/VizGrid';
import TreeDynamic from './Dynamic';
// `Static` means that the component doesn't depend on the initial query to
// load all products and locations info.
import TreeStatic from './Static';

interface Variables {
  hsProductClass: ProductClass;
  sitcProductClass: ProductClass;
}

const variables: Variables = {
  hsProductClass: ProductClass.HS,
  sitcProductClass: ProductClass.SITC,
};

const indexQuery = gql`
  fragment productFields on Product {
    id
    shortName
    level
    topLevelParent {
      id
      type: productType
    }
  }
  query TreeIndex($hsProductClass: ProductClass!, $sitcProductClass: ProductClass!) {
    treeIndexLocations: allLocations {
      id
      shortName
      level
      isDataTrustworthy
      hasReportedServicesInAnyYear
      hasReportedServicesLastYear
    }

    treeIndexGroups: allGroups {
      id
      shortName: groupName
      groupType
      parent{
        id
      }
    }

    treeIndexHSProducts: allProducts(productClass: $hsProductClass) {
      ... productFields
    }

    treeIndexSITCProducts: allProducts(productClass: $sitcProductClass) {
      ... productFields
    }
  }
`;

export interface FetchedLocationDatum {
  id: Location['id'];
  shortName: Location['shortName'];
  level: Location['locationLevel'];
  isDataTrustworthy: Location['isDataTrustworthy'];
  hasReportedServicesInAnyYear: Location['hasReportedServicesInAnyYear'];
  hasReportedServicesLastYear: Location['hasReportedServicesLastYear'];
}

export interface FetchedProductDatum {
  id: Product['id'];
  shortName: Product['shortName'];
  level: Product['level'];
  topLevelParent: {
    type: Product['topLevelParent']['productType'],
  };
}

export interface ISuccessResponse {
  treeIndexLocations: FetchedLocationDatum[];
  treeIndexHSProducts: FetchedProductDatum[];
  treeIndexSITCProducts: FetchedProductDatum[];
}

type Props = SetActivePanelProps & TutorialModalProps;

const Tree = (props: Props) => {
  const {activePanel, setActivePanel, setIsTutorialModalOpen, isTutorialModalOpen, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled, setDisplayIntraRegionTradeDisclaimer} = props;

  const {loading, error, data} = useQuery<ISuccessResponse, Variables>(indexQuery, {variables});

  // Need to reset visualization disabling for region/subregion:
  // In case where select region/subregion while on complexity viz,
  // and then choose a trade visualization, need to render the chart
  // as expected because trade visualizations are available for regions/subregions
  setDisableVisualizationForRegion(false);
  const [highlighted, setHighlighted] = useState<string | undefined>(undefined);

  let output: React.ReactElement<any> | null;
  if (loading === true) {
    output = (
      <>
        <ChartContainer>
          <Loading/>
        </ChartContainer>
        <SharePlaceholder />
      </>
    );
  } else if (error !== undefined) {
    console.error(error);
    output = (
      <>
        <ChartContainer>
          <Loading/>
        </ChartContainer>
        <SharePlaceholder />
      </>
    );
  } else if (data !== undefined) {
    const {treeIndexHSProducts, treeIndexLocations, treeIndexGroups, treeIndexSITCProducts} = data;
    output = (
      <>
        <TreeDynamic
          treeIndexLocations={treeIndexLocations}
          treeIndexGroups={treeIndexGroups}
          treeIndexHSProducts={treeIndexHSProducts}
          treeIndexSITCProducts={treeIndexSITCProducts}
          isTutorialModalOpen={isTutorialModalOpen}
          setIsTutorialModalOpen={setIsTutorialModalOpen}
          setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
          highlighted={highlighted}
          setHighlighted={setHighlighted}
        />
      </>
    );
  } else {
    output = null;
  }
  return (
    <>
      <TreeStatic
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}
        setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
        setHighlighted={setHighlighted}
        highlighted={highlighted}
      />
      {output}
    </>
  );
};

export default Tree;
