import React from 'react';
import {
  newRegionColorMap,
  groupedRegionColorMap,
  RegionColor,
} from '../../Utils';
import Choice from './Choice';
import {
  FetchedLocationDatum,
} from './graphQLTypes';
import {
  IChoice,
  IChoiceGetElementInput,
} from './otherTypes';
import RegionContainer from './RegionContainer';
import {
  SelectorContainer,
} from './sharedStyling';
import {
  disabledCategoryColor,
  unselectedCategoryColor,
  useIsolateBehaviorForSelector,
} from './Utils';

interface Props {
  selectedCategories: string[];
  allCategories: FetchedLocationDatum[];
  setSelected: (selectedCategories: string[]) => void;
}

export default (props: Props) => {
  const {selectedCategories, allCategories, setSelected} = props;
  const {
    onShowHideClick,
    onIsolateClick,
    spotlightMode,
  } = useIsolateBehaviorForSelector({
    selectedCategories, allCategories, setSelected,
  });

  const choiceElems = allCategories.map(category => {
    const {id, groupName} = category;
    // const retrievedColor = newRegionColorMap.get(id);
    const retrievedColor = groupedRegionColorMap.get(id);
    const regularColor: string = retrievedColor ? retrievedColor : RegionColor.Other;
    if (regularColor === undefined) {
      console.error('Cannot retrieve color for region ' + id);
    }
    const choice: IChoice = {
      color: regularColor,
      value: id,
      // label: shortName,
      label: groupName,
      getElement: ({isSelected, isEnabled}: IChoiceGetElementInput) => {
        let color: string;
        if (isEnabled === false) {
          color = disabledCategoryColor;
        } else if (isSelected === false) {
          color = unselectedCategoryColor;
        } else {
          color = regularColor;
        }
        return (
          <RegionContainer text={groupName} color={color}/>
        );
      },
    };

    return (
      <Choice
        choice={choice}
        selectedCategories={selectedCategories}
        onShowHideClick={onShowHideClick}
        onIsolateClick={onIsolateClick}
        isEnabled={true}
        allowSectorToggle={true}
        spotlightMode={spotlightMode}
        key={`choice-${choice.value}`}
      />
    );
  });

  return (
    <SelectorContainer>
      {choiceElems}
    </SelectorContainer>
  );

};
