import noop from 'lodash-es/noop';
import partition from 'lodash-es/partition';
import React from 'react';
import styled from 'styled-components';
import { smallMediaQuery } from '../../countryProfiles/Grid';
import {
  ProductClass,
  ProductType,
} from '../../graphQL/types/shared';
import usePrevious from '../../tree/usePreviousHook';
import {
  failIfValidOrNonExhaustive,
  newHSColorMap,
  newSITCColorMap,
} from '../../Utils';
import GoodsChoice from './Choice';
import {
  FetchedProductDatum,
} from './graphQLTypes';
import {
  IChoice,
  IChoiceGetElementInput,
  SpotlightMode,
} from './otherTypes';
import ProductContainer from './ProductContainer';
import ServiceChoice from './ServiceChoice';
import {
  Divider,
} from './sharedStyling';
import {
  disabledCategoryColor,
  unselectedCategoryColor,
  useIsolateBehaviorForSelector,
} from './Utils';

export const ProductSectorLabel = styled.legend`
  color: #666;
  text-align: right;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 60px;
  margin-right: 10px;

  @media ${smallMediaQuery} {
    font-size: 0.6rem;
    width: 45px;
    margin-right: 6px;
  }
`;

type Props = {
  allCategories: FetchedProductDatum[];
  productClass: ProductClass;
  isServicesEnabled: boolean;
  isServicesNotAvailableForAllYears: boolean;
  hasServicesNotAvailableForSomeYearsTooltipBeenShown: boolean;
  servicesDisabledMessage:
    {useAlternateMessage: false} |
    {useAlternateMessage: true, message: string}
} & (
  {allowSectorToggle: false} |
  {allowSectorToggle: true,
    setSelected: (ids: string[]) => void,
    selectedCategories: string[],
  }
);

export default (props: Props) => {

  const {
    productClass,
    isServicesEnabled,
    allCategories,
    isServicesNotAvailableForAllYears,
    hasServicesNotAvailableForSomeYearsTooltipBeenShown,
    servicesDisabledMessage,
  } = props;

  if (allCategories.length === 0) {
    return null;
  }

  let setSelected: (ids: string[]) => void;
  let selectedCategories: string[];
  if (props.allowSectorToggle === true) {
    setSelected = props.setSelected;
    selectedCategories = props.selectedCategories;
  } else {
    setSelected = noop;
    selectedCategories = allCategories.map(({id}) => id);
  }

  const {
    onShowHideClick,
    onIsolateClick,
    setSpotlightMode,
    spotlightMode,
  } = useIsolateBehaviorForSelector({
    selectedCategories, allCategories, setSelected,
  });

  const prevProductClass = usePrevious(productClass);
  if (prevProductClass !== undefined && prevProductClass !== productClass) {
    const nextSpotlightMode = (productClass !== prevProductClass) ? SpotlightMode.Off : spotlightMode;
    // Needed to prevent infinite rendering loop:
    if (nextSpotlightMode !== spotlightMode) {
      setSpotlightMode(nextSpotlightMode);
    }
  }

  let colorMap: typeof newHSColorMap;
  if (productClass === ProductClass.HS) {
    colorMap = newHSColorMap;
  } else if (productClass === ProductClass.SITC) {
    colorMap = newSITCColorMap;
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
    // The following lines will never be executed:
    colorMap = undefined as any;
  }

  const choices = allCategories.map(info => {
    const {id, shortName, productType} = info;
    const regularColor = colorMap.get(id);
    if (regularColor === undefined) {
      throw new Error('Cannot retrieve color for category ' + id);
    }
    const choice: IChoice & {productType: ProductType} = {
      color: regularColor,
      value: id,
      label: shortName,
      productType,
      getElement: ({isSelected, isEnabled}: IChoiceGetElementInput) => {
          let color: string;
          if (isEnabled === false) {
            color = disabledCategoryColor;
          } else if (isSelected === false) {
            color = unselectedCategoryColor;
          } else {
            color = regularColor;
          }
          return (
            <ProductContainer id={id} color={color}/>
          );
      },
    };
    return choice;
  });

  const [services, goods] = partition<IChoice & {productType: ProductType}>(
    choices, choice => choice.productType === ProductType.Service,
  );

  let goodsChoiceElems: Array<React.ReactElement<any>>;
  let serviceChoiceElems: Array<React.ReactElement<any>>;

  let goodsSortedByProductId: (any & {productType: ProductType})[] = [...goods].sort((productA: any, productB: any) => {
    const nameA = productA.value.toUpperCase();
    const nameB = productB.value.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  
    return 0;  
  });


  if (props.allowSectorToggle === true) {
    goodsChoiceElems = goodsSortedByProductId.map(({productType, ...rest}) => (
      <GoodsChoice
        choice={rest}
        selectedCategories={selectedCategories}
        isEnabled={true}
        allowSectorToggle={props.allowSectorToggle}
        onShowHideClick={onShowHideClick}
        onIsolateClick={onIsolateClick}
        spotlightMode={spotlightMode}
        key={`choice-${rest.value}`}
      />
    ));
    serviceChoiceElems = services.map(({productType, ...rest}) => (
      <React.Fragment key={`choice-${rest.value}`}>
        <ServiceChoice
          choice={rest}
          selectedCategories={selectedCategories}
          onShowHideClick={onShowHideClick}
          onIsolateClick={onIsolateClick}
          isEnabled={isServicesEnabled}
          spotlightMode={spotlightMode}
          allowSectorToggle={props.allowSectorToggle}
          areSomeYearsNotAvailable={isServicesNotAvailableForAllYears}
          hasServicesNotAvailableForSomeYearsTooltipBeenShown={hasServicesNotAvailableForSomeYearsTooltipBeenShown}
          disabledMessage={servicesDisabledMessage}
        />
        <Divider
          style={{marginLeft: '4px', marginRight: '4px'}}
        />
      </React.Fragment>
    ));
  } else {
    goodsChoiceElems = goodsSortedByProductId.map(({productType, ...rest}) => (
      <GoodsChoice
        choice={rest}
        selectedCategories={selectedCategories}
        isEnabled={true}
        allowSectorToggle={props.allowSectorToggle}
        key={`choice-${rest.value}`}
      />
    ));
    serviceChoiceElems = services.map(({productType, ...rest}) => (
      <React.Fragment key={`choice-${rest.value}`}>
        <ServiceChoice
          choice={rest}
          selectedCategories={selectedCategories}
          isEnabled={isServicesEnabled}
          allowSectorToggle={props.allowSectorToggle}
          areSomeYearsNotAvailable={isServicesNotAvailableForAllYears}
          hasServicesNotAvailableForSomeYearsTooltipBeenShown={hasServicesNotAvailableForSomeYearsTooltipBeenShown}
          disabledMessage={servicesDisabledMessage}
        />
        <Divider
          style={{marginLeft: '4px', marginRight: '4px'}}
        />
      </React.Fragment>
    ));
  }

  return (
    <>
      <ProductSectorLabel>
        {__lexiconText('sectorLabel')}
      </ProductSectorLabel>
      <Divider style={{marginRight: '4px'}}/>
      {serviceChoiceElems}
      {goodsChoiceElems}
    </>
  );
};
