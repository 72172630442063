import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';
import {
  TreeMapType,
  LocationLevel
} from '../../graphQL/types/shared';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
  SuccessResponse,
} from '../useTreeMapLocationAggregationQueries';
import {
  IOverlayDatum,
} from './otherTypes';
import transformLocations from './transformLocations';
import transformProducts from './transformProducts';
import {transformDataWithNewTopLevelParent} from "../Utils";

interface IInput {
  fetchResult: SuccessResponse['treeMap'];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  type: TreeMapType;
  productClass: ProductClass | null;
  year: number;
}
const transform = (input: IInput): Map<string, IOverlayDatum> => {
  const {
    fetchResult, tradeDirection, tradeFlow, type, productClass, year, locationLevel
  } = input;



  if (type === TreeMapType.CPY_C ||
        type === TreeMapType.CCPY_CC) {
    const addExtraProductDetails = (type === TreeMapType.CPY_C);
    if (productClass === null) {
      throw new Error('Product class cannot be null in CPY_C and CCPY_CC');
    }
    return transformProducts({
      fetchResult: fetchResult.treeMap as FetchedProductDatum[],
      tradeDirection, tradeFlow, productClass,
      year, addExtraProductDetails,
    });
  } else if (type === TreeMapType.CPY_P || type === TreeMapType.CCY_C ||
              type === TreeMapType.CCPY_CP) {


    let groupedData = transformDataWithNewTopLevelParent(fetchResult.treeMap, locationLevel, fetchResult.regions, fetchResult.subregions);

    
    return transformLocations({
      // fetchResult: fetchedData as FetchedLocationDatum[],
      fetchResult: groupedData as FetchedLocationDatum[],
      tradeDirection, tradeFlow, year
    });

    // if(dataForTooltips && dataForTooltips.tooltipData) {
    //   const {tooltipData, regions, subregions} = dataForTooltips;
    //   let groupedTooltipData = locationLevel === LocationLevel.country ? tooltipData : transformDataWithNewTopLevelParent(tooltipData, locationLevel, regions, subregions);
  
    //   return transformLocations({
    //     fetchResult: groupedTooltipData as FetchedLocationDatum[],
    //     tradeDirection, tradeFlow, year,
    //   });
  
    // }
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
    // The following lines will never be executed:
    return new Map();
  }
};

export default transform;
