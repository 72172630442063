import isEqual from 'lodash-es/isEqual';
import debounce from 'lodash/debounce';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import styled from 'styled-components';
import {
  LocationLevel,
  ProductClass,
  ProductLevel,
  QueryLevel,
  TradeDirection,
  TradeFlow,
} from '../graphQL/types/shared';
import { generateStringLocationId } from '../graphQL/Utils';
import {
  TreeMapType,
  DataFacet,
} from '../graphQL/types/shared';
import { TreeMapInput } from '../graphQL/types/shared';
import { useQueryParams } from '../routing';
import ErrorOverlay from '../sharedComponents/GraphError';
import ProductCategorySelector from '../sharedComponents/newCategorySelector/Product';
import RegionSelector from '../sharedComponents/newCategorySelector/Region';
import {
  SelectorContainer as ProductCategorySelectorContainer,
} from '../sharedComponents/newCategorySelector/sharedStyling';
import DataNotesDialog from '../sharedComponents/newDataNotes';
import ExportsPanel from '../sharedComponents/newExportsPanel';
import {
  Mode as ExportPanelMode,
} from '../sharedComponents/newExportsPanel/types';
import GraphShare, {
  DataNotesProps,
  TutorialModalProps,
} from '../sharedComponents/newGraphShare';
import TriggerDataDownloadQuery from '../sharedComponents/newGraphShare/TriggerDataDownloadQuery';
import PlayButton from '../sharedComponents/PlayButton';
import RadioSelector from '../sharedComponents/radioSelector';
import {
  IChoice,
} from '../sharedComponents/radioSelector/Utils';
import YearSelector, {
  TimelineType,
} from '../sharedComponents/timeline';
import {
  failIfValidOrNonExhaustive,
  INewDropdownOption,
  overlayPortalContainerId,
  Target,
} from '../Utils';
// import ChartShownTitle from '../viz/ChartShownTitle';
import { parseQueryParamsIntoQueryStore } from '../viz/routingUtils';
import Tooltip from '../viz/Tooltip';
import {
  RightLowerControlContainer,
} from '../viz/TwoColumnVizControlsGrid';
import {
  clampYear,
  locationDetailLevelChoices,
  newExpandedProductDetailLevelChoices,
  newProductDetailLevelChoices,
  VizType
} from '../viz/Utils';
import {
  ChartContainer,
  ChartHeader,
  ChartTitle,
  // NewChartHeader,
  // NewChartHeaderTopRow,
  // NewChartHeaderTopRowCell1,
  // NewChartHeaderTopRowCell2,
  // NewChartHeaderTopRowCell3,
  // NewChartHeaderTopRowCell4,
  // NewChartHeaderBottomRow,
  // NewChartHeaderBottomRowCell1,
  // NewChartHeaderBottomRowCell2,
  // NewChartHeaderBottomRowCell3,
  // NewChartHeaderBottomRowCell4,
  NewChartTitle,
  HighlightContainer,
  SpinnerContainer,
  TooltipsContainer,
  YearSelectorContainerWithPlayButton,
  YearSelectorInnerContainer,
} from '../viz/VizGrid';
import { vizSettingsPortalId } from '../viz/VizSettings';
import convertURLParamsToTreeMapInput, {
  ErrorCode,
} from './convertURLParamsToTreeMapInput';
import DetailOverlayRenderProp from './detailOverlay';
import determineDataWarnings from './determineDataWarnings';
import DropdownRenderProp from './dropdown';
import GraphTotalRenderProp from './graphTotal';
import GraphTotal from './graphTotal/GraphTotal';
import HoverTooltipsRenderProp from './hoverTooltips';
import {
  ISuccessResponse,
} from './index';
import ChartRenderProp from './newChart';
import transform, {ITransformInput} from './newChart/transform';
import {
  getFetchedDataToGraphData as getFetchedDataToExportsGraphData,
  graphDataToPDF,
  graphDataToPNG,
  graphDataToSVG,
} from './newExports/transform';
import treeURLTransformers from './URLTransformers';
import useNavigation from './useNavigation';
import usePlayButtonBehavior from './usePlayButtonBehaviorHook';
import usePrevious from './usePreviousHook';
import { combinedQuery } from './useTreeMapLocationAggregationQueries';
import { NewGraphTitle } from '../viz/NewChartTitle';

//#region styled-components:
const Dropdown = styled(Select)`
  width: 100%;
`;
//#endregion

enum DialogType {
  None,
  Share,
  Exports,
  DataNotes,
  DataDownload,
  AggregationExplainer
}

type Props = ISuccessResponse & TutorialModalProps;

const Tree = (props: Props) => {

  const {isTutorialModalOpen, setIsTutorialModalOpen, setDisplayIntraRegionTradeDisclaimer, highlighted, setHighlighted} = props;
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(treeURLTransformers, queryParams);
  const {productClass, tradeDirection, tradeFlow} = queryStore;

  const [chartKey, setChartKey] = useState<string>(`tree-${window.innerWidth}-${window.innerHeight}`);
  const [dataForTooltips, changeDataForTooltips] = useState({tooltipData: undefined, regions: undefined, subregions: undefined});

  const setDataForTooltips = ({tooltipData, regions, subregions}) => {

    if(dataForTooltips.tooltipData === undefined) {
      changeDataForTooltips({tooltipData, regions, subregions});
    }
  }

  useEffect(() => {
    const refreshOnResize = debounce(() => {
      setChartKey(`tree-${window.innerWidth}-${window.innerHeight}`);
    }, 300);
    window.addEventListener('resize', refreshOnResize);
    return () => {
      window.removeEventListener('resize', refreshOnResize);
    };
  }, []);

  const year = clampYear(productClass, queryStore.year);
  const urlParamsConversionResult = convertURLParamsToTreeMapInput({
    country: queryStore.country,
    queryLevel: queryStore.queryLevel,
    product: queryStore.product,
    target: queryStore.target,
    partner: queryStore.partner,
    productClass,
  });



  //#region UI-related controls
  const [productLevel, setProductLevel] = useState<ProductLevel>(ProductLevel.fourDigit);
  const [locationLevel, setLocationLevel] = useState<LocationLevel>(LocationLevel.country);
  // const [highlighted, setHighlighted] = useState<string | undefined>(undefined);
  const [hovered, setHovered] = useState<string | undefined>(undefined);
  const [detailed, setDetailed] = useState<string | undefined>(undefined);
  const [chartContainerSize, setChartContainerSize] = useState<{width: number, height: number} | undefined>(undefined);
  const resetHovered = () => setHovered(undefined);
  const clearHighlight = () => setHighlighted(undefined);
  const resetDetailed = () => setDetailed(undefined);



  const {changeYear} = useNavigation();

  //#endregion

  interface StopPlayDiffInput {
    country: number | undefined;
    product: number | undefined;
    partner: number | undefined;
    target: Target;
    tradeDirection: TradeDirection;
    tradeFlow: TradeFlow;
    productClass: ProductClass;
    productLevel: ProductLevel;
    locationLevel: LocationLevel;
  }

  const {
    stopPlaying, togglePlayButton, isPlaying,
  } = usePlayButtonBehavior<StopPlayDiffInput>({
    changeYear, productClass,
    currentYear: year,
    stopPlayDiffInput: {
      country: queryStore.country,
      product: queryStore.product,
      partner: queryStore.partner,
      target: queryStore.target,
      tradeDirection: queryStore.tradeDirection,
      tradeFlow, productClass,
      productLevel, locationLevel,
    },
  });

  //#region Initialize `selectedCategories`
  const {
    treeIndexHSProducts, treeIndexSITCProducts, treeIndexLocations, treeIndexGroups
  } = props;
  const allHSCategories = treeIndexHSProducts.filter(
    elem => elem.level === ProductLevel.section,
  ).map(elem => elem.id);
  const allSITCCategories = treeIndexSITCProducts.filter(
    elem => elem.level === ProductLevel.section,
  ).map(elem => elem.id);

  const allRegionGroups = treeIndexGroups.filter(
    elem => elem.groupType === LocationLevel.region
    ).map(elem => elem.id);


  if(queryStore.country && queryStore.partner && queryStore.target === Target.Partner) {
    let primaryQueryLevel = queryStore.queryLevel;
    let primaryLocationId = `${queryStore.queryLevel}-${queryStore.country}`;
    let targetQueryLevel = queryStore.partner.includes("group") ? QueryLevel.Group : QueryLevel.Location;

    // Determine if target is parent or child of primary location
    const parentOrChildRelationship = treeIndexGroups.filter(elem => {
      return (elem.id === primaryLocationId && elem.parent.id === queryStore.partner) || (elem.id === queryStore.partner && elem.parent.id === primaryLocationId);
    });


    // If primary and target location are identical
    if(primaryLocationId === queryStore.partner) {
      setDisplayIntraRegionTradeDisclaimer(true);
    } else if(parentOrChildRelationship.length != 0) {
      setDisplayIntraRegionTradeDisclaimer(true);
    } else {
      setDisplayIntraRegionTradeDisclaimer(false);
    }
  } else {
    setDisplayIntraRegionTradeDisclaimer(false);
  }

  let initialSelectedCategories: string[];
  let treeMapType: TreeMapType | undefined;
  if (urlParamsConversionResult.isValid === true) {
    const {info: {facet}} = urlParamsConversionResult;
    if (facet === TreeMapType.CPY_C || facet === TreeMapType.CCPY_CC) {
      if (productClass === null) {
        throw new Error('Product class cannot be null');
      }
      if (productClass === ProductClass.HS) {
        initialSelectedCategories = allHSCategories;
      } else if (productClass === ProductClass.SITC) {
        initialSelectedCategories = allSITCCategories;
      } else {
        // These lines will never be executed:
        initialSelectedCategories = [];
        failIfValidOrNonExhaustive(productClass, 'Invalid productClass' + productClass);
      }
      treeMapType = facet;
    } else if (facet === TreeMapType.CPY_P ||
                facet === TreeMapType.CCPY_CP ||
                facet === TreeMapType.CCY_C) {
      initialSelectedCategories = allRegionGroups;
      treeMapType = facet;
    } else {
      // These lines will never be executed:
      initialSelectedCategories = [];
      treeMapType = undefined;
      console.error('Invalid tree map type');
    }
  } else {
    initialSelectedCategories = [];
    treeMapType = undefined;
  }
  const [selectedCategories, setSelectedCategories] = useState<string[]>(initialSelectedCategories);
  const hiddenCategories =
    initialSelectedCategories.filter(category => !selectedCategories.find(selected => selected === category));
  //#endregion

  //#region Update `selectedCategories` in response to change in product class or graph type
  const prevProductClass = usePrevious(productClass);
  const prevTreeMapType = usePrevious(treeMapType);

  if (urlParamsConversionResult.isValid === true) {
    const hasChangedFromProductToLocation =
      (prevTreeMapType === TreeMapType.CPY_C ||
        prevTreeMapType === TreeMapType.CCPY_CC ||
        prevTreeMapType === undefined) &&
      (treeMapType === TreeMapType.CPY_P ||
        treeMapType === TreeMapType.CCY_C ||
        treeMapType === TreeMapType.CCPY_CP);
    const hasChangedFromLocationToProduct =
      (prevTreeMapType === TreeMapType.CPY_P ||
        prevTreeMapType === TreeMapType.CCY_C ||
        prevTreeMapType === TreeMapType.CCPY_CP ||
        prevTreeMapType === undefined) &&
      (treeMapType === TreeMapType.CPY_C ||
        treeMapType === TreeMapType.CCPY_CC);
    const hasChangedProductClass =
        (prevTreeMapType === TreeMapType.CPY_C ||
          prevTreeMapType === TreeMapType.CCPY_CC) &&
        (treeMapType === TreeMapType.CPY_C ||
          treeMapType === TreeMapType.CCPY_CC) &&
        (productClass !== prevProductClass) &&
        productClass !== undefined &&
        prevProductClass !== undefined;

    let newSelectedCategories: string[];
    if (hasChangedFromLocationToProduct) {


          if (productClass === ProductClass.HS) {
            newSelectedCategories = allHSCategories;
          } else if (productClass === ProductClass.SITC) {
            newSelectedCategories = allSITCCategories;
          } else if (productClass === null || productClass === undefined) {
            throw new Error('Product class must be valid for CPY_C or CCPY_CC tree map types');
          } else {
            // The following lines will never be executed:
            newSelectedCategories = [];
            failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
          }
    } else if (hasChangedFromProductToLocation) {

          newSelectedCategories = allRegionGroups;
    } else if (hasChangedProductClass) {
          if (productClass === ProductClass.HS) {
            newSelectedCategories = allHSCategories;
          } else if (productClass === ProductClass.SITC) {
            newSelectedCategories = allSITCCategories;
          } else if (productClass === null || productClass === undefined) {
            throw new Error('Product class must be valid for CPY_C or CCPY_CC tree map types');
          } else {
            // The following lines will never be executed:
            newSelectedCategories = [];
            failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
          }
    } else {
          newSelectedCategories = selectedCategories;
    }
      if (!isEqual(newSelectedCategories, selectedCategories)) {
          setSelectedCategories(newSelectedCategories);
        }
  }
  //#endregion
  //#region chart container size measurement
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (chartContainerRef && chartContainerRef.current) {
      const node = chartContainerRef.current;
      const width = node.clientWidth;
      const height = node.clientHeight;
      if (chartContainerSize === undefined ||
          chartContainerSize.width !== width || chartContainerSize.height !== height) {
      setChartContainerSize({width, height});
      }
    }
  }, [chartKey]);
  //#endregion

  //#region Exports related:
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.None);
  const showShare = () => setDialogType(DialogType.Share);
  const showDownload = () => setDialogType(DialogType.Exports);
  const showDataNotes = () => setDialogType(DialogType.DataNotes);
  const showDataDownload = () => setDialogType(DialogType.DataDownload);
  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const node = document.querySelector<HTMLElement>(`#${overlayPortalContainerId}`);
    overlayPortalContainerNodeRef.current = node;
  }, [overlayPortalContainerNodeRef.current]);
  const closeOverlay = () => setDialogType(DialogType.None);
  //#endregion

  //#region Render
  let chartElement: React.ReactElement<any> | null;
  let errorOverlay: React.ReactElement<any> | null;
  let detailLevelSelector: React.ReactElement<any> | null;
  let highlightDropdown: React.ReactElement<any> | null;
  let hoverTooltips: React.ReactElement<any> | null;
  let detailOverlay: React.ReactElement<any> | null;
  let graphTotal: React.ReactElement<any> | null;
  let categorySelector: React.ReactElement<any> | null;
  let dialog: React.ReactElement<any> | null;
  let share: React.ReactElement<any> | null;

  let titleInfo;

  let [graphTitle, setGraphTitle] = useState('');

  if (urlParamsConversionResult.isValid === true) {
    errorOverlay = null;
    const {info} = urlParamsConversionResult;
    let productLevelArg = productLevel;
    if (info.product) {
      const productArray = info.product.includes('HS') ? treeIndexHSProducts : treeIndexSITCProducts;
      const targetProduct = productArray.find(d => d.id === info.product);
      if (targetProduct) {
        productLevelArg = targetProduct.level;
      }
    }
    const treeMapInputVariables: TreeMapInput = {
      facet: info.facet,
      productClass,
      year,
      productLevel: productLevelArg,
      locationLevel,
      location: info.location,
      product: info.product,
      partner: info.partner,
    };


    let isDataNotesWarningActive, dataNotes;

    
    if(info.queryLevel === QueryLevel.Location) {
      const {
        isDataNotesWarningActive: isDataNotesWarningActiveCheck, dataNotes: dataNotesCheck,
      } = determineDataWarnings({
        type: info.facet,
        product: info.product,
        location: generateStringLocationId(info.location),
        productClass,
        locations: treeIndexLocations,
        hsProducts: treeIndexHSProducts,
        sitcProducts: treeIndexSITCProducts,
      });


      isDataNotesWarningActive = isDataNotesWarningActiveCheck;
      dataNotes = dataNotesCheck;
  
    } else {
      isDataNotesWarningActive = false;
      dataNotes = undefined;
    }


    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive,
      launchDataNotes: showDataNotes,
    };

    share = (
      <GraphShare
        launchShare={showShare}
        launchExports={showDownload}
        showDataDownload={showDataDownload}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        closeDetailOverlay={resetDetailed}
      />
    );

    if (chartContainerSize === undefined) {
      chartElement = null;
      hoverTooltips = null;
      dialog = null;
    } else {
      const chartRenderInput = {
        width: chartContainerSize.width,
        height: chartContainerSize.height,
        selectedCategories,
        tradeDirection,
        tradeFlow,
        onCellClick: setDetailed,
        onMouseOverCell: setHovered,
        onMouseLeaveChart: resetHovered,
        highlighted,
        setDataForTooltips
      };
      chartElement = (
        <ChartRenderProp
          chartKey={chartKey}
          variables={treeMapInputVariables}
          input={chartRenderInput}
          setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
        />
      );
      const hoverTooltipsRenderInput = {
        width: chartContainerSize.width,
        height: chartContainerSize.height,
        selectedCategories,
        tradeDirection,
        tradeFlow,
        hovered, highlighted,
        locationLevel,
        clearHighlightTooltip: clearHighlight,
        // dataForTooltips
      };
      hoverTooltips = (
        <HoverTooltipsRenderProp
          variables={treeMapInputVariables}
          input={hoverTooltipsRenderInput}
        />
      );

      const fetchedDataToGraphData = getFetchedDataToExportsGraphData({
        width: chartContainerSize.width,
        height: chartContainerSize.height,
        selectedCategories,
        tradeDirection,
        tradeFlow,
      });
      const otherInputs = {
        width: chartContainerSize.width,
        height: chartContainerSize.height,
      };


      //#region Exports sharing panel:
      const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;
      if (overlayPortalContainerNode !== null && dialogType !== DialogType.None) {
        if (dialogType === DialogType.Share ||
              dialogType === DialogType.Exports) {

          let initialMode: ExportPanelMode;
          if (dialogType === DialogType.Share) {
            initialMode = ExportPanelMode.Share;
          } else if (dialogType === DialogType.Exports) {
            initialMode = ExportPanelMode.Export;
          } else {
            // The following lines will never be executed:
            initialMode = undefined as any;
            failIfValidOrNonExhaustive(dialogType, 'Invalid dialog status type' + dialogType);
          }

          dialog = ReactDOM.createPortal(
            (
              <ExportsPanel
                initialMode={initialMode}
                closeOverlay={closeOverlay}
                variables={treeMapInputVariables}
                query={combinedQuery}
                otherInputs={otherInputs}
                fetchedDataToGraphData={fetchedDataToGraphData}
                svgConfig={{
                  isEnabled: true,
                  graphDataToDownloadableData: graphDataToSVG,
                }}
                pngConfig={{
                  isEnabled: true,
                  graphDataToDownloadableData: graphDataToPNG,
                }}
                pdfConfig={{
                  isEnabled: true,
                  graphDataToDownloadableData: graphDataToPDF,
                }}
                graphTitle={graphTitle}
              />
            ),
            overlayPortalContainerNode,
          );
        } else if (dialogType === DialogType.DataNotes) {
          dialog = ReactDOM.createPortal(
            (<DataNotesDialog dataIssues={dataNotes} closeOverlay={closeOverlay}/>),
            overlayPortalContainerNode,
          );
        }  else if (dialogType === DialogType.DataDownload) {
          const generateCsvData = (input: ITransformInput) => {
            const fetchedData = (input.fetchedData as any).treeMap;
            const {csvData} = transform({...input, fetchedData, regions: dataForTooltips.regions, subregions: dataForTooltips.subregions});
            return csvData;
          };
          dialog = (
            <TriggerDataDownloadQuery
              variables={treeMapInputVariables}
              query={combinedQuery}
              otherInputs={{selectedCategories, tradeDirection, tradeFlow, width: 0, height: 0}}
              graphTitle={graphTitle}
              closeOverlay={closeOverlay}
              fetchedDataToCsvData={generateCsvData}
            />
          );
        } else {
          // The following lines will never be executed:
          dialog = null;
          failIfValidOrNonExhaustive(dialogType, 'Invalid dialog type ' + dialogType) ;
        }
      } else {
        dialog = null;
      }
      //#endregion

      const detailOverlayRenderInput = {
        hideOverlay: resetDetailed,
        detailed,
        tradeDirection,
        tradeFlow,
        year,
        dataForTooltips,
        locationLevel
      };
      detailOverlay = (
        <DetailOverlayRenderProp
          variables={treeMapInputVariables}
          input={detailOverlayRenderInput}
        />
      );
    }


    const updateHighlightFromDropdown = (selection: INewDropdownOption | null) => {
      if (selection === null) {
        setHighlighted(undefined);
      } else {
        const {value} = selection;
        setHighlighted(value);
      }
    };

    const dropdownRenderInput = {
      selectedCategories,
      tradeDirection,
      tradeFlow,
      highlighted,
      locationLevel,
      onChange: updateHighlightFromDropdown,
      DropdownComponent: Dropdown as any,
      DropdownContainerComponent: RightLowerControlContainer,
    };
    highlightDropdown = (
      <DropdownRenderProp
        variables={treeMapInputVariables}
        input={dropdownRenderInput}
      />
    );

    const graphTotalRenderInput = {
      selectedCategories,
      tradeDirection,
      tradeFlow,
      hiddenCategories,
      queryLevel: info.queryLevel,
      locationLevel: treeMapInputVariables.locationLevel
    };
    graphTotal = (
      <GraphTotalRenderProp
        variables={treeMapInputVariables}
        input={graphTotalRenderInput}
      />
    );
    if (info.facet === TreeMapType.CPY_C || info.facet === TreeMapType.CCPY_CC) {

      let choices: Array<IChoice<ProductLevel>>;
      if (productClass === ProductClass.HS) {
        choices = newExpandedProductDetailLevelChoices;
      } else if (productClass === ProductClass.SITC) {
        choices = newProductDetailLevelChoices;
      } else if (productClass === null) {
        throw new Error('Product class cannot be null in CPY_C or CCPY_CP');
      } else {
        // The following lines will never be executed:
        choices = [];
        failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
      }

      detailLevelSelector = (
        <RadioSelector
          tooltipText={__lexiconText('applicationWide.productDetailLevelSelector.tooltipText')}
          mainLabel={__lexiconText('applicationWide.productDetailLevelSelector.mainLabel')}
          choices={choices}
          selected={productLevel}
          onClick={(level: ProductLevel) => { setHighlighted(undefined); setProductLevel(level) }}
        />
      );

      const isServicesEnabled = (info.facet !== TreeMapType.CCPY_CC);
      categorySelector = (
        <ProductCategorySelector
          productClass={productClass}
          selectedCategories={selectedCategories}
          isServicesEnabled={isServicesEnabled}
          isServicesNotAvailableForAllYears={false}
          hasServicesNotAvailableForSomeYearsTooltipBeenShown={false}
          setSelected={setSelectedCategories}
          Container={ProductCategorySelectorContainer}
          extraDOMAttrOnRootNode={undefined}
        />
      );
    } else if (info.facet === TreeMapType.CPY_P ||
                info.facet === TreeMapType.CCY_C ||
                info.facet === TreeMapType.CCPY_CP) {
      detailLevelSelector = (
        <RadioSelector
          tooltipText={__lexiconText('applicationWide.countryDetailLevelSelector.tooltipText')}
          mainLabel={__lexiconText('applicationWide.countryDetailLevelSelector.mainLabel')}
          choices={locationDetailLevelChoices}
          selected={locationLevel}
          onClick={(level: LocationLevel) => { setHighlighted(undefined); setLocationLevel(level) }}
        />
      );
      categorySelector = (
        <RegionSelector
          selectedCategories={selectedCategories}
          setSelected={setSelectedCategories}
        />
      );
    } else {
      // The following lines will never be executed:
      detailLevelSelector = null;
      categorySelector = null;
      failIfValidOrNonExhaustive(info.facet, 'Invalid tree map type ' + info.facet);
    }


      titleInfo = {
        location: info.location,
        queryLevel: info.queryLevel,
        product: info.product,
        facet: info.facet,
        partner: info.partner
      };
    
  } else {
    chartElement = null;
    titleInfo = null;
    const {errorCode} = urlParamsConversionResult;
    let message: string;
    if (errorCode === ErrorCode.PickTypeOfAggregationEntity) {
      message = __lexiconText('error.productMode');
    } else if (errorCode === ErrorCode.PickPartner) {
      message = __lexiconText('error.partnerMode');
    } else {
      // The following lines will never be executed:
      message = '';
      failIfValidOrNonExhaustive(errorCode, 'Invalid error code ' + errorCode);
    }
    errorOverlay = (
      <ErrorOverlay message={message}/>
    );
    detailLevelSelector = null;
    highlightDropdown = null;
    hoverTooltips = null;
    detailOverlay = null;
    graphTotal = (
      <GraphTotal
        numerator={0}
        denominator={0}
        totalGoods={0}
        totalServices={undefined}
        hiddenCategories={hiddenCategories}
        selectedCategories={selectedCategories}
      />
    );
    categorySelector = null;
    dialog = null;

    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive: false,
      launchDataNotes: showDataNotes,
    };
    share = (
      <GraphShare
        launchShare={null}
        launchExports={null}
        showDataDownload={null}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        closeDetailOverlay={resetDetailed}
      />
    );
    const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;
    if (overlayPortalContainerNode !== null && dialogType === DialogType.DataNotes) {
      dialog = ReactDOM.createPortal(
        (<DataNotesDialog dataIssues={[]} closeOverlay={closeOverlay}/>),
        overlayPortalContainerNode,
      );
    }
  }

  const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

  let vizSettings: React.ReactElement<any> | null;
  if (vizSettingsNodeRef) {
    vizSettings = ReactDOM.createPortal((
      <>
        {detailLevelSelector}
      </>
    ), vizSettingsNodeRef);
  } else {
    vizSettings = null;
  }

  const changeTimelineYear = (inputYear: number) => {
    stopPlaying();
    changeYear(inputYear);
  };


  let chartTitleElem: JSX.Element | null = null;
  if(titleInfo) {
    chartTitleElem = 
      <NewGraphTitle
      country={titleInfo.location}
      queryLevel={titleInfo.queryLevel}
      product={titleInfo.product}
      partner={titleInfo.partner}
      year={year}
      tradeDirection={tradeDirection}
      productClass={productClass}
      // setTitle={() => setNewChartTitle}
      vizType={VizType.Tree}
      facet={titleInfo.facet as DataFacet}
      graphTotal={graphTotal}
      setTitle={(val: string) => setGraphTitle(val)}
    />

  }

  return (
    <>
      {chartTitleElem}
      <ChartContainer ref={chartContainerRef}>
        {chartElement}
      </ChartContainer>
      <TooltipsContainer>
        {hoverTooltips}
      </TooltipsContainer>
      <SpinnerContainer>
        {errorOverlay}
      </SpinnerContainer>
      {detailOverlay}
      {categorySelector}
      <HighlightContainer>
        {highlightDropdown}
      </HighlightContainer>
      <YearSelectorContainerWithPlayButton>
        <PlayButton isPlaying={isPlaying} onClick={togglePlayButton} size={30}/>
        <YearSelectorInnerContainer>
          <YearSelector
            productClass={productClass} year={year}
            type={TimelineType.SingleYear}
            onYearChange={changeTimelineYear}
          />
        </YearSelectorInnerContainer>
      </YearSelectorContainerWithPlayButton>
      {vizSettings}
      {share}
      {dialog}
    </>
  );
  //#endregion
};

export default Tree;
