import noop from 'lodash-es/noop';
import React, {
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  IChoice,
  SpotlightMode,
} from './otherTypes';
import {
  Anchor,
  hideIsolateTooltipBackgroundColor,
  Root,
  TooltipContainer,
  TooltipControlItem,
  TooltipControlItemCircle,
  TooltipControlItemCircleSelected,
  TooltipControlItemText,
  TooltipControls,
  TooltipTitle,
} from './ProductChoiceUtils';
import {
  useIsolateBehaviorForTooltips,
} from './Utils';

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--strike-through-opacity'?: StandardLonghandProperties['opacity'];
    '--background-color'?: BackgroundColorProperty;
    '--tooltip-text-color'?: string;
    '--tooltip-background-color'?: string;
  }
}

const notAvailableTooltipTextColor = 'rgb(108, 126, 142)';

const CloseButton = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: ${notAvailableTooltipTextColor};
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

type Props = {
  choice: IChoice;
  selectedCategories: string[];
  minWidthChoice?: number;
  isEnabled: boolean;
  areSomeYearsNotAvailable: boolean;
  hasServicesNotAvailableForSomeYearsTooltipBeenShown: boolean;
  disabledMessage:
    {useAlternateMessage: false} |
    {useAlternateMessage: true, message: string}
} & (
  {allowSectorToggle: false} |
  {allowSectorToggle: true,
    spotlightMode: SpotlightMode;
    onShowHideClick: (value: string) => void;
    onIsolateClick: (value: string) => void;
  }
);

enum TooltipStatus {
  None,
  Regular,
  Disabled,
  NotAllYearsAvailable,
}
export default (props: Props) => {
  const {
    choice: {getElement, label, value, color},
    selectedCategories,
    isEnabled,
    minWidthChoice, allowSectorToggle,
  } = props;

  const [tooltipStatus, setTooltipStatus] = useState<TooltipStatus>(TooltipStatus.None);

  const iconElemRef = useRef<HTMLDivElement | null>(null);

  let spotlightMode: SpotlightMode = SpotlightMode.Off;
  let setOnShowHideClick: (value: string) => void = noop;
  let setOnIsolateClick: (value: string) => void = noop;

  if (props.allowSectorToggle === true) {
    spotlightMode = props.spotlightMode;
    setOnShowHideClick = props.onShowHideClick;
    setOnIsolateClick = props.onIsolateClick;
  }

  const {onShowHideClick, onIsolateClick} = useIsolateBehaviorForTooltips({
    onShowHideClick: setOnShowHideClick,
    onIsolateClick: setOnIsolateClick,
    isEnabled: props.isEnabled,
    choice: props.choice,
    DOMElemRef: iconElemRef,
  });

  if (props.areSomeYearsNotAvailable === true &&
      props.hasServicesNotAvailableForSomeYearsTooltipBeenShown === false) {
    setTooltipStatus(TooltipStatus.NotAllYearsAvailable);
  }

  const onMouseEnter = () => {
    // Hover has no effect if the "services data not available for certain years"
    // tooltip is being shown. That tooltip needs to be closed first before the other
    // tooltip can show/hide:
    if (tooltipStatus !== TooltipStatus.NotAllYearsAvailable) {
      const newStatus = isEnabled ? TooltipStatus.Regular : TooltipStatus.Disabled;
      setTooltipStatus(newStatus);
    }
  };

  const onMouseLeave = () => {
    // Hover has no effect if the "services data not available for certain years"
    // tooltip is being shown. That tooltip needs to be closed first before the other
    // tooltip can show/hide:
    if (tooltipStatus === TooltipStatus.Disabled || tooltipStatus === TooltipStatus.Regular) {
      setTooltipStatus(TooltipStatus.None);
    }
  };

  const hideDataNotAvailalbleForAllYearsTooltip = () => {
    setTooltipStatus(TooltipStatus.None);
  };

  const dataNotAvailableTooltipStyle: React.CSSProperties = {
    '--tooltip-text-color': 'rgb(107, 124, 145)',
    '--tooltip-background-color': 'rgb(198, 210, 222)',
    'width': '200px',
    'padding': '0.75rem',
    'fontSize': '0.875rem',
    'boxShadow': 'unset',
    'borderRadius': '2px',
  };

  let tooltip: React.ReactNode;
  if (tooltipStatus === TooltipStatus.None) {
    tooltip = null;
  } else if (tooltipStatus === TooltipStatus.Regular) {
    let isShowOn: boolean;
    let isIsolateOn: boolean;
    if (spotlightMode === SpotlightMode.On) {
      isShowOn = false;
      isIsolateOn = selectedCategories.includes(value);
    } else {
      isIsolateOn = false;
      isShowOn = selectedCategories.includes(value);
    }

    const tooltipStyle: React.CSSProperties = {
      '--tooltip-text-color': 'white',
      '--tooltip-background-color': hideIsolateTooltipBackgroundColor,
      'borderTop': `10px solid ${color}`,
    };
    const showIconStyle: React.CSSProperties = {
      '--background-color': isShowOn ? 'white' : 'transparent',
    };
    const isolateIconStyle: React.CSSProperties = {
      '--background-color': isIsolateOn ? 'white' : 'transparent',
    };
    const ShowIconCircleComponent = !isShowOn && !isIsolateOn
      ? TooltipControlItemCircleSelected : TooltipControlItemCircle;
    const IsolateIconCircleComponent = isIsolateOn
      ? TooltipControlItemCircleSelected : TooltipControlItemCircle;

    let tooltipControls: React.ReactElement<any> | null;
    if (allowSectorToggle === true) {
      tooltipControls = (
        <TooltipControls>
            <TooltipControlItem onClick={onShowHideClick} style={showIconStyle}>

              <ShowIconCircleComponent/>
              <TooltipControlItemText>
                Hide
              </TooltipControlItemText>
            </TooltipControlItem>

            <TooltipControlItem onClick={onIsolateClick} style={isolateIconStyle}>

              <IsolateIconCircleComponent/>
              <TooltipControlItemText>
                Isolate
              </TooltipControlItemText>
            </TooltipControlItem>

          </TooltipControls>
       );
    } else {
      tooltipControls = null;
    }

    tooltip = (
      <TooltipContainer style={tooltipStyle}>
        <TooltipTitle>{label}</TooltipTitle>
        {tooltipControls}
      </TooltipContainer>
    );

  } else if (tooltipStatus === TooltipStatus.NotAllYearsAvailable) {
    tooltip = (
      <TooltipContainer style={dataNotAvailableTooltipStyle}>
        {__lexiconText('notAvailableForAllYearsText')}
        <CloseButton
          dangerouslySetInnerHTML={{__html: '&times;'}}
          onClick={hideDataNotAvailalbleForAllYearsTooltip}
        />
      </TooltipContainer>
    );

  } else if (tooltipStatus === TooltipStatus.Disabled) {
    const message = (props.disabledMessage.useAlternateMessage === true) ?
                      props.disabledMessage.message : __lexiconText('notAvailableText');
    tooltip = (
      <TooltipContainer style={dataNotAvailableTooltipStyle}>
        {message}
      </TooltipContainer>
    );
  } else {
    failIfValidOrNonExhaustive(tooltipStatus, 'Invalid tooltip status');
    // These lines will never be executed:
    tooltip = null;
  }

  const isIconSelected = selectedCategories.includes(value);

  const minWidth = (minWidthChoice === undefined) ? '' : `${minWidthChoice}px`;
  const rootStyle: React.CSSProperties = {
    '--strike-through-opacity': (isEnabled ? 0 : 1),
  };
  const anchorStyle = {
    cursor: isEnabled ? 'pointer' : 'default',
    minWidth,
  };

  return (
    <Root
      style={rootStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Anchor
        style={anchorStyle}
        ref={iconElemRef}
      >
        {getElement({isEnabled, isSelected: isIconSelected})}
      </Anchor>
      {tooltip}
    </Root>
  );
};
