import {
  groupedRegionColorMap,
  newRegionColorMap
} from '../../Utils';
import {
  FetchedLocationDatum,
} from '../useTreeMapLocationAggregationQueries';
import {
  ITooltipDatum,
} from './otherTypes';

import sum from 'lodash-es/sum';
import {
  LocationLevel,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';
import {
  formatPercentage,
  formatTradeValue,
} from '../../numberFormatters';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';
import performLayout from '../newChart/performLayout';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  filterBySelectedCategories,
} from '../newChart/transformUtils';
import getTradeLabel from './getTradeLabel';

interface IInput {
  fetchResult: FetchedLocationDatum[];
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

interface ITransformed {
  id: string;
  title: string;
  monetaryValue: number;
  rows: IRow[];
  topLevelParentId: string;
}




const transform = (input: IInput): Map<string, ITooltipDatum> => {
  const {
    fetchResult, width, height, selectedCategories, tradeDirection, tradeFlow, locationLevel
  } = input;
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filtered = filterByMonetaryValues(withComputedTradeValues);
  const totalSum = sum(filtered.map(({monetaryValue}) => monetaryValue));

  const transformed = filtered.map(elem => {
    const {
      monetaryValue,
      location: {
        id, shortName,
        topLevelParent: {id: topLevelParentId},
      },
    } = elem;
    const tradeInfo: IRow = {
      label: getTradeLabel(tradeDirection, tradeFlow),
      value: formatTradeValue(monetaryValue),
    };
    const percentage = monetaryValue / totalSum;
    const shareInfo: IRow = {
      label: __lexiconText('applicationWide.share'),
      value: formatPercentage(percentage),
    };
    const rows = [tradeInfo, shareInfo];
    // const useColorMap = locationLevel === LocationLevel.country ? groupedRegionColorMap : newRegionColorMap;
    const useColorMap = groupedRegionColorMap;
    const color = useColorMap.get(topLevelParentId);
    if (color === undefined) {
      throw new Error('Cannot retrieve color for section ' + topLevelParentId);
    }
    const out: ITransformed = {
      id,
      title: shortName,
      monetaryValue,
      rows, topLevelParentId,
    };
    return out;
  });


  const filteredByCategories = filterBySelectedCategories(transformed, selectedCategories);
  const withCellLayout = performLayout(filteredByCategories, {x0: 0, y0: 0, x1: width, y1: height});


  const mapPrecursor = withCellLayout.map(elem => {
    const {
      x0, y0, x1, y1, id,
      monetaryValue: _unused,
      ...rest
    } = elem;
    const out: ITooltipDatum = {
      ...rest,
      id,
      tooltipX: (x0 + x1) / 2,
      tooltipY: y0,
    };
    return [id, out] as [string, ITooltipDatum];
  });
  return new Map(mapPrecursor);
};

export default transform;
