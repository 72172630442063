import {
  ColorQuadruplet,
} from '../sharedComponents/webglUtils';
import { groups, rollups, sum, index } from "d3-array";
import { LocationLevel } from '../graphQL/types/shared';
// import structuredClone from "core-js-pure/actual/structured-clone";
import cloneDeep from 'lodash-es/cloneDeep';

export const hoverTooltipDelay = 350; // in milliseconds

export const strokeColor: ColorQuadruplet = [255, 255, 255, 255];
export const halfStrokeWidth = 0.5;

export const transformDataWithNewTopLevelParent = (fetchedData, selectedLevel, regions, subregions) => {
  let groupedData;

  if(selectedLevel === LocationLevel.country) {
    // Do nothing
    groupedData = fetchedData.map(f => {

      let copy = cloneDeep(f);
      const top = f.location.topLevelParent;
      if(top.shortName === "North America" || top.shortName === "South America") {
        copy.location.topLevelParent.id = "group-2";
      } else if(top.shortName === "Africa") {
        copy.location.topLevelParent.id = "group-1";
      } else if(top.shortName === "Asia") {
        copy.location.topLevelParent.id = "group-3";

      } else if(top.shortName === "Europe") {
        copy.location.topLevelParent.id = "group-4";

      } else if(top.shortName === "Oceania") {
        copy.location.topLevelParent.id = "group-5";

      } else if(top.shortName === "Other") {
        copy.location.topLevelParent.id = "group-6";

      }
      return copy; 
      });
    // Need to temporarily replace topLevelParent.id with correct value
  } else if(selectedLevel === LocationLevel.subregion) {

    groupedData = subregions.map(s => {
      const groupId = s.id;
      const groupName = s.groupName;
      const groupMembers = s.members.map(m => m.id);
      const filteredFetchedData = fetchedData.filter(f => groupMembers.includes(f.location.id));

      return {

        exportValue: sum(filteredFetchedData, d => d.exportValue),
        importValue: sum(filteredFetchedData, d => d.importValue),
        location: {
          id: groupId,
          shortName: groupName,
          longName: groupName,
          code: groupName,
          topLevelParent: s.parent
        }
      }
    });


  } else if(selectedLevel === LocationLevel.region) {

    groupedData = regions.map(s => {
      const groupId = s.id;
      const groupName = s.groupName;
      const groupMembers = s.members.map(m => m.id);
      const filteredFetchedData = fetchedData.filter(f => groupMembers.includes(f.location.id));
      return {

        exportValue: sum(filteredFetchedData, d => d.exportValue),
        importValue: sum(filteredFetchedData, d => d.importValue),
        location: {
          id: groupId,
          shortName: groupName,
          longName: groupName,
          code: groupName,
          topLevelParent: {
            id: groupId,
            shortName: groupName
          }
        }
      }
    });

  }

  return groupedData;
}