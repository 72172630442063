import React from 'react';
import {
  TradeDirection,
  TradeFlow,
  TreeMapInput,
  TreeMapType,
} from '../../graphQL/types/shared';
import HighlightDropdown, {
  IProps as IHighlightDropdownProps,
} from '../../sharedComponents/NewHighlightDropdown';
import {
  INewDropdownOption,
} from '../../Utils';
import { transformDataWithNewTopLevelParent } from "../Utils";
import {
  FetchedLocationDatum,
  FetchedProductDatum,
  Result,
  useCombinedQuery,
} from '../useTreeMapLocationAggregationQueries';
import transformLocation from './transformLocation';
import transformProduct from './transformProduct';

type IRenderPropInput = {
  selectedCategories: string[]
  tradeDirection: TradeDirection
  tradeFlow: TradeFlow
  highlighted: string | undefined
  onChange: (option: INewDropdownOption | null) => void,
} & Pick<IHighlightDropdownProps, 'DropdownComponent' | 'DropdownContainerComponent'>;

const getRenderProp =
  (input: IRenderPropInput) =>
    (queryResult: Result): React.ReactNode => {

  const {data, error, loading, variables} = queryResult;
  const {
    highlighted: selected, selectedCategories, tradeDirection, tradeFlow, locationLevel, onChange,
    DropdownComponent, DropdownContainerComponent,
  } = input;
  let options: INewDropdownOption[];
  if (loading ) {
    options = [];
  } else if (error) {
    options = [];
  } else if (data !== undefined) {
    const {treeMap} = data;

    const {facet: type, productClass} = variables;
    if (type === TreeMapType.CPY_C ||
        type === TreeMapType.CCPY_CC) {
      options = transformProduct({
        fetchResult: treeMap as FetchedProductDatum[],
        selectedCategories, productClass,
        tradeDirection, tradeFlow,
      });
    } else if (type === TreeMapType.CCY_C ||
                type === TreeMapType.CCPY_CP ||
                type === TreeMapType.CPY_P)  {

      let groupedData = transformDataWithNewTopLevelParent(treeMap, locationLevel, data.regions, data.subregions);

      options = transformLocation({
        fetchResult: groupedData as FetchedLocationDatum[],
        selectedCategories, tradeDirection, tradeFlow,
      });
    } else {
      options = [];
    }
  } else {
    options = [];
  }
  return (
    <HighlightDropdown
      label={__lexiconText('applicationWide.highlightDropdown.mainLabel')}
      tooltipText={__lexiconText('applicationWide.highlightDropdown.tooltipText')}
      options={options}
      value={selected}
      onChange={onChange}
      isClearable={true}
      DropdownComponent={DropdownComponent}
      DropdownContainerComponent={DropdownContainerComponent}
    />
  );
};

interface Props {
  input: IRenderPropInput;
  variables: TreeMapInput;
}

const RenderProp = (props: Props) => {
  const {
    input, variables,
  } = props;
  const chartRenderProp = getRenderProp(input);
  const result = useCombinedQuery(variables);
  return (<>{chartRenderProp(result as any)}</>);
};

export default RenderProp;
