import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  TreeMapInput,
  TreeMapType,
  QueryLevel
} from '../graphQL/types/shared';
import {
  generateStringProductId,
} from '../graphQL/Utils';
import {
  generateStringLocationOrGroupId
} from '../graphQL/Utils';
import {
  Target,
} from '../Utils';

export enum ErrorCode {
  PickTypeOfAggregationEntity = 'PickTypeOfAggregationEntity',
  PickPartner = 'PickPartner',
}

type PartialTreeMapInput = Pick<TreeMapInput, 'facet' | 'location' | 'queryLevel' | 'product' | 'partner'>;

type IResult = {
  isValid: true
  info: PartialTreeMapInput;
} | {
  isValid: false
  errorCode: ErrorCode,
};

interface IInput {
  country: number | undefined;
  queryLevel: QueryLevel | undefined;
  product: number | undefined;
  target: Target;
  partner: number | undefined;
  productClass: ProductClass;
}

export default (input: IInput): IResult => {
  const {
    country, queryLevel, product, partner, target, productClass,
  } = input;


  let output: IResult;
  if (target === Target.Product) {
    if (country !== undefined && product === undefined) {
      output = {
        isValid: true,
        info: {
          facet: TreeMapType.CPY_C,
          // location: generateStringLocationId(country),
          location: generateStringLocationOrGroupId(country, queryLevel),
          queryLevel: queryLevel,
          // location: generateStringLocationOrGroupId(country, level),
          product: null,
          partner: null,
        },
      };
    } else if (country === undefined && product !== undefined) {
      output = {
        isValid: true,
        info: {
          facet: TreeMapType.CPY_P,
          location: null,
          queryLevel: undefined,
          product: generateStringProductId({productClass, id: product}),
          partner: null,
        },
      };
    } else if (country !== undefined && product !== undefined) {
      output = {
        isValid: true,
        info: {
          facet: TreeMapType.CCPY_CP,
          // location: generateStringLocationId(country),
          location: generateStringLocationOrGroupId(country, queryLevel),
          queryLevel: queryLevel,
          product: generateStringProductId({productClass, id: product}),
          partner: null,
        },
      };
    } else {
      output = {
        isValid: false,
        errorCode: ErrorCode.PickTypeOfAggregationEntity,
      };
    }
  } else if (target === Target.Partner) {
    if (country !== undefined && partner !== undefined) {
      output = {
        isValid: true,
        info: {
          facet: TreeMapType.CCPY_CC,
          // location: generateStringLocationId(country),
          location: generateStringLocationOrGroupId(country, queryLevel),
          queryLevel: queryLevel,
          product: null,
          partner,
          // partner: generateStringLocationId(partner),
          location: generateStringLocationOrGroupId(country, queryLevel),
        },
      };
    } else if (country !== undefined && partner === undefined) {
      output = {
        isValid: true,
        info: {
          facet: TreeMapType.CCY_C,
          // location: generateStringLocationId(country),
          location: generateStringLocationOrGroupId(country, queryLevel),
          queryLevel: queryLevel,
          product: null,
          partner: null,
        },
      };
    } else {
      output = {
        isValid: false,
        errorCode: ErrorCode.PickPartner,
      };
    }
  } else {
    // These lines will never be executed:
    output = null as any;
  }
  
  return output;
};
