import React from 'react';
import {
  TradeDirection,
  TradeFlow,
  TreeMapInput,
} from '../../graphQL/types/shared';
import {
  Result,
  useCombinedQuery,
} from '../useTreeMapLocationAggregationQueries';
import GraphTotal from './GraphTotal';
import transform from './transform';

interface IRenderPropInput {
  selectedCategories: string[];
  hiddenCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

interface Props {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const Wrapper = (props: Props) => {
  const {
    renderPropInput: {selectedCategories, tradeDirection, tradeFlow, hiddenCategories, queryLevel, locationLevel},
    queryResult: {loading, error, data, variables},
  } = props;

  let output: React.ReactElement<any> | null;
  if (loading === true) {
    output = null;
  } else if (error !== undefined) {
    output = null;
    console.error(error);
  } else if (data !== undefined) {
    const {treeMap} = data;
    const {numerator, denominator, totalGoods, totalServices} = transform({
      fetchResult: treeMap,
      variables,
      otherInputs: {
        selectedCategories, tradeDirection, tradeFlow,
      },
    });


    output = (
      <GraphTotal
        numerator={numerator}
        denominator={denominator}
        totalGoods={totalGoods}
        totalServices={totalServices}
        hiddenCategories={hiddenCategories}
        selectedCategories={selectedCategories}
        queryLevel={queryLevel}
        locationLevel={locationLevel}
      />
    );
  } else {
    output = null;
  }
  return output;
};

const getRenderProp =
  (input: IRenderPropInput) =>
    (queryResult: Result) => (
      <Wrapper renderPropInput={input} queryResult={queryResult}/>
    );

interface RenderPropProps {
  input: IRenderPropInput;
  variables: TreeMapInput;
}

const RenderProp = (props: RenderPropProps) => {
  const {
    input, variables,
  } = props;
  const chartRenderProp = getRenderProp(input);
  const result = useCombinedQuery(variables);
  return (<>{chartRenderProp(result as any)}</>);
};

export default RenderProp;
