import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';
import {
  INewDropdownOption,
  newGetCountryDropdownLabel,
  newGetCountryDropdownSearchString,
} from '../../Utils';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  filterBySelectedCategories,
} from '../newChart/transformUtils';
import {
  FetchedLocationDatum,
} from '../useTreeMapLocationAggregationQueries';

interface ITransformInput {
  fetchResult: FetchedLocationDatum[];
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

const transform = (input: ITransformInput): INewDropdownOption[] => {
  const {
    tradeDirection, tradeFlow, fetchResult, selectedCategories,
  } = input;
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filtered = filterByMonetaryValues(withComputedTradeValues);
  const transformed = filtered.map(elem => {
    const {
      location: {
        topLevelParent: {id: topLevelParentId},
        ...restOfLocation
      },
      ...restOfElem
    } = elem;
    return {
      ...restOfElem,
      ...restOfLocation,
      topLevelParentId,
    };
  });

  const filteredByCategories = filterBySelectedCategories(transformed, selectedCategories);
  const sortedByCode = filteredByCategories.sort(
    (a, b) => (a.code <= b.code) ? -1 : 1,
  );
  const result: INewDropdownOption[] = sortedByCode.map(({id, shortName, longName, code, level}) => {
    const label = newGetCountryDropdownLabel(shortName, level, code);
    const searchString = newGetCountryDropdownSearchString(
      shortName, longName, level, code,
    );
    return {
      value: id,
      label,
      searchString,
    };
  });
  return result;
};

export default transform;
