import React from 'react';
import styled from 'styled-components';
import { exploreGray } from '../viz/VizGrid';

const OuterContainer = styled.div`
  grid-column: 1 / 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const hoverColor = '#A41034';

// Taken from https://css-tricks.com/making-pure-css-playpause-button/
const InnerContainer = styled.div`
  transition: 100ms all ease;
  cursor: pointer;
  border: 0;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent transparent transparent ${exploreGray};
  position: relative;
  top: 30%;

  &:hover {
    border-color: transparent transparent transparent ${hoverColor};
  }
`;

interface IProps {
  isPlaying: boolean;
  onClick: () => void;
  // The length of one side of the equilateral triangular "play" symbol:
  size: number;
}

export default class extends React.Component<IProps> {
  render() {
    const {isPlaying, onClick, size} = this.props;

    // This is the height of an equilateral triangle with side `arrowSide`:
    const width = size / 2 / Math.tan(Math.PI / 6);

    const baseStyle = {
      height: `${size}px`,
    };

    let styles: React.CSSProperties;
    if (isPlaying) {
      styles = {
        ...baseStyle,
        borderStyle: 'double',
        borderWidth: `0px 0 0px ${size}px`,
      };
    } else {
      styles = {
        ...baseStyle,
        borderStyle: 'solid',
        borderWidth: `${size / 2}px 0 ${size / 2}px ${width}px`,
      };
    }
    return (
      <OuterContainer onClick={onClick}>
        <InnerContainer style={styles}/>
      </OuterContainer>
    );
  }
}
