import React from 'react';
import styled from 'styled-components';
import {
  localTooltipZIndexHighPriority,
} from '../../cssVariables';
import {
  getRows,
} from '../../sharedComponents/DetailOverlay';

import {
  xIconHTMLEntity,
} from '../../Utils';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';

//#region Styled-components
const tooltipFillColor = 'rgba(92, 100, 125, 0.9)';
const tooltipBorderColor = 'rgba(255, 255, 255, 0.2)';

const monetaryTextColor = 'orange';

// These are in `vw` units:
const tooltipWidth = 10;
const arrowHeight = tooltipWidth / 12;
const arrowWidth = arrowHeight;

// This is the root element for the highlight tooltip.
// Use a different `z-index` for hover tooltip:
export const Root = styled.div`
  --translation-x: 0;
  --translation-y: 0;

  top: 0;
  left: 0;
  z-index: ${localTooltipZIndexHighPriority};
  position: absolute;
  width: ${tooltipWidth}vw;
  transform: translateX(calc(-50% + var(--translation-x))) translateY(calc(-100% + var(--translation-y)));
  background-color: ${tooltipFillColor};
  border: 1px solid ${tooltipBorderColor};
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-bottom: 0;
  }

  &::before {
    bottom: -${arrowHeight}vw;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipBorderColor};
    border-width: ${arrowWidth}vw;
  }

  &::after {
    bottom: calc(-${arrowHeight}vw + 1px);
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipFillColor};
    border-width: calc(${arrowWidth}vw - 1px);
  }
`;
const ContentContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.5rem 0;
  color: white;
  text-align: center;
`;
const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const BodyText = styled.div`
  display: flex;
  align-items: center;
  height: 1.15rem;
  font-size: 0.65rem;
`;

const TableItem = styled(BodyText)`
  &:nth-child(3),
  &:nth-child(4) {
    color: ${monetaryTextColor};
  }

  &:nth-child(2n + 1) {
    justify-content: flex-start;
  }

  &:nth-child(2n + 2) {
    justify-content: flex-end;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  cursor: pointer;
  color: #bbb;
  width: 1rem;
  height: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  pointer-events: auto;
  font-size: 0.8rem;
`;
//#endregion

type ICloseTooltipProps = {
  allowCloseTooltip: false,
} | {
  allowCloseTooltip: true,
  closeTooltip: () => void;
};

type IProps = ICloseTooltipProps & {
  title: string;
  rows: IRow[];
  tooltipX: number
  tooltipY: number
};

export default (props: IProps) => {
  const {title, rows, tooltipX, tooltipY} = props;
  const style = {
    '--translation-x': `${tooltipX}px`,
    '--translation-y': `calc(${tooltipY}px - ${arrowHeight}vw)`,
  };

  const rowElems = getRows(rows, TableItem);

  const closeButton = props.allowCloseTooltip ? (
    <CloseButton
      onClick={props.closeTooltip}
      dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
    />
  ) : null;

  return (
    <Root style={style}>
      <ContentContainer>
        <TitleContainer>{title}</TitleContainer>
        <BodyContainer>
          {rowElems}
        </BodyContainer>
      </ContentContainer>
      {closeButton}
    </Root>
  );
};
