import noop from 'lodash-es/noop';
import React, {
  useRef,
  useState,
} from 'react';
import {
  IChoice,
  SpotlightMode,
} from './otherTypes';
import {
  Anchor,
  hideIsolateTooltipBackgroundColor,
  Root,
  TooltipContainer,
  TooltipControlItem,
  TooltipControlItemCircle,
  TooltipControlItemCircleSelected,
  TooltipControlItemText,
  TooltipControls,
  TooltipTitle,
} from './ProductChoiceUtils';
import {
  useIsolateBehaviorForTooltips,
} from './Utils';

type Props = {
  choice: IChoice;
  selectedCategories: string[];
  isEnabled: boolean;
} & (
  {allowSectorToggle: false} |
  {allowSectorToggle: true,
    spotlightMode: SpotlightMode;
    onShowHideClick: (value: string) => void;
    onIsolateClick: (value: string) => void;
  }
);

export default (props: Props) => {
  const [tooltipShown, setTooltipShown] = useState<boolean>(false);

  const iconElemRef = useRef<HTMLDivElement | null>(null);

  const {
    choice: {getElement, label, value, color},
    selectedCategories,
    isEnabled,
  } = props;

  let spotlightMode: SpotlightMode = SpotlightMode.Off;
  let setOnShowHideClick: (value: string) => void = noop;
  let setOnIsolateClick: (value: string) => void = noop;

  if (props.allowSectorToggle === true) {
    spotlightMode = props.spotlightMode;
    setOnShowHideClick = props.onShowHideClick;
    setOnIsolateClick = props.onIsolateClick;
  }

  const {onShowHideClick, onIsolateClick} = useIsolateBehaviorForTooltips({
    onShowHideClick: setOnShowHideClick,
    onIsolateClick: setOnIsolateClick,
    isEnabled: props.isEnabled,
    choice: props.choice,
    DOMElemRef: iconElemRef,
  });

  const onMouseEnter = () => setTooltipShown(true);
  const onMouseLeave = () => setTooltipShown(false);

  let isShowOn: boolean;
  let isIsolateOn: boolean;
  if (spotlightMode === SpotlightMode.On) {
    isShowOn = false;
    isIsolateOn = selectedCategories.includes(value);
  } else {
    isIsolateOn = false;
    isShowOn = selectedCategories.includes(value);
  }

  const isIconSelected = selectedCategories.includes(value);

  const rootStyle = {
    '--strike-through-opacity': (isEnabled ? 0 : 1),
  };

  const anchorStyle = {
    cursor: isEnabled ? 'pointer' : 'default',
  };

  const ShowIconCircleComponent = !isShowOn && !isIsolateOn
    ? TooltipControlItemCircleSelected : TooltipControlItemCircle;
  const IsolateIconCircleComponent = isIsolateOn
    ? TooltipControlItemCircleSelected : TooltipControlItemCircle;

  let tooltip: React.ReactNode;
  if (tooltipShown === true) {
    const tooltipStyle = {
      '--tooltip-text-color': 'white',
      '--tooltip-background-color': hideIsolateTooltipBackgroundColor,
      'borderTop': `10px solid ${color}`,
    };

    let tooltipControls: React.ReactElement<any> | null;
    if (props.allowSectorToggle === true) {
      tooltipControls = (
        <TooltipControls>
            <TooltipControlItem onClick={onShowHideClick}>

              <ShowIconCircleComponent/>
              <TooltipControlItemText>
                Hide
              </TooltipControlItemText>
            </TooltipControlItem>

            <TooltipControlItem onClick={onIsolateClick}>

              <IsolateIconCircleComponent/>
              <TooltipControlItemText>
                Isolate
              </TooltipControlItemText>
            </TooltipControlItem>

          </TooltipControls>
       );
    } else {
      tooltipControls = null;
    }

    tooltip = (
      <TooltipContainer style={tooltipStyle}>
        <TooltipTitle>{label}</TooltipTitle>
        {tooltipControls}
      </TooltipContainer>
    );
  } else {
    tooltip = null;
  }

  return (
    <Root
      style={rootStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Anchor
        style={anchorStyle}
        ref={iconElemRef}
      >
        {getElement({isEnabled, isSelected: isIconSelected})}
      </Anchor>
      {tooltip}
    </Root>
  );
};
