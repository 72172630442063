import React from 'react';
import {
  ProductLevel,
  TradeDirection,
  TradeFlow,
  TreeMapInput,
  TreeMapType,
} from '../../graphQL/types/shared';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Loading from '../../sharedComponents/GraphLoading';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  Result,
  SuccessResponse,
  useCombinedQuery,
} from '../useTreeMapLocationAggregationQueries';
import Chart from './Chart';
import {
  NumCellsTier,
} from './domIndependentDrawUtils';
import transform from './transform';
import useLastSuccesfulFetch from './useLastSuccesfulFetch';

export interface IRenderPropInput {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  highlighted: string | undefined;
  onCellClick: (id: string) => void;
  onMouseOverCell: (id: string) => void;
  onMouseLeaveChart: () => void;
}

const determineTreeMapNumCellsTier = (type: TreeMapType, productLevel: ProductLevel | null): NumCellsTier => {
  let numCellsTier: NumCellsTier;
  if (type === TreeMapType.CPY_C || type === TreeMapType.CCPY_CC) {

    if (productLevel === ProductLevel.section ||
          productLevel === ProductLevel.twoDigit ||
          productLevel === ProductLevel.fourDigit) {
      numCellsTier = NumCellsTier.Small;
    } else if (productLevel === ProductLevel.sixDigit) {
      numCellsTier = NumCellsTier.Large;
    } else if (productLevel === null) {
      throw new Error('Product level cannot be null for CPY_C and CCPY_CC');
    } else {
      failIfValidOrNonExhaustive(productLevel, 'Invalid product level ' + productLevel);
      // The following lines will never be executed:
      numCellsTier = NumCellsTier.Small;
    }
  } else if (type === TreeMapType.CPY_P ||
              type === TreeMapType.CCY_C ||
              type === TreeMapType.CCPY_CP) {
    numCellsTier = NumCellsTier.Small;
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
    // The following lines will never be executed:
    numCellsTier = NumCellsTier.Small;
  }
  return numCellsTier;
};
interface IProps {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const isFetchedDataEmpty = ({treeMap}: SuccessResponse) => (treeMap.length === 0);

const combineNewIntoOldRenderPropInputs =
  (oldInput: IRenderPropInput, newInput: IRenderPropInput): IRenderPropInput => {

  const {
    width: _unused1,
    height: _unused2,
    onCellClick: _unused4,
    onMouseOverCell: _unused5,
    onMouseLeaveChart: _unsed6,
    highlighted: _unused7,
    ...restOfOldInput
  } = oldInput;
  return {
    ...restOfOldInput,
    width: newInput.width,
    height: newInput.height,
    onCellClick: newInput.onCellClick,
    onMouseOverCell: newInput.onMouseOverCell,
    onMouseLeaveChart: newInput.onMouseLeaveChart,
    highlighted: newInput.highlighted,
  };
};

const ChartContainer = (props: IProps) => {


  const lastSuccesfulFetch = useLastSuccesfulFetch<SuccessResponse, TreeMapInput, IRenderPropInput>({
    isFetchedDataEmpty,
    inputs: {
      extraInputs: props.renderPropInput,
      queryResult: props.queryResult,
    },
  });
  const {renderPropInput, queryResult} = props;
  const setDataForTooltips = renderPropInput.setDataForTooltips;

  let output: React.ReactElement<any> | null;
  if (queryResult.loading === true) {
    if (lastSuccesfulFetch !== undefined) {
      const combinedRenderPropInput = combineNewIntoOldRenderPropInputs(
        lastSuccesfulFetch.extraInputs, props.renderPropInput,
      );
      
      const {transformed} = transform({
        fetchedData: lastSuccesfulFetch.data.treeMap,
        setDataForTooltips,
        regions: lastSuccesfulFetch.data.regions,
        subregions: lastSuccesfulFetch.data.subregions,
        variables: lastSuccesfulFetch.variables,
        otherInputs: {
          width: combinedRenderPropInput.width,
          height: combinedRenderPropInput.height,
          selectedCategories: combinedRenderPropInput.selectedCategories,
          tradeDirection: combinedRenderPropInput.tradeDirection,
          tradeFlow: combinedRenderPropInput.tradeFlow,
        },
      });

      const numCellsTier = determineTreeMapNumCellsTier(
        lastSuccesfulFetch.variables.facet,
        lastSuccesfulFetch.variables.productLevel,
      );
      output = (
        <>
          <Chart
            highlighted={renderPropInput.highlighted}
            cells={transformed}
            numCellsTier={numCellsTier}
            chartContainerHeight={combinedRenderPropInput.height}
            chartContainerWidth={combinedRenderPropInput.width}
            onCellClick={combinedRenderPropInput.onCellClick}
            onMouseOverCell={combinedRenderPropInput.onMouseOverCell}
            onMouseLeaveChart={combinedRenderPropInput.onMouseLeaveChart}
          />
          <Loading/>
        </>
      );
    } else {
      output = (<Loading/>);
    }
  } else if (queryResult.error) {
    output = (
      <ErrorOverlay message={__lexiconText('error.fetchData')}/>
    );
  } else if (queryResult.data) {

    
    const {transformed} = transform({
      fetchedData: queryResult.data.treeMap,
      regions: queryResult.data.regions,
      subregions: queryResult.data.subregions,
      setDataForTooltips,
      variables: queryResult.variables,
      otherInputs: {
        width: renderPropInput.width,
        height: renderPropInput.height,
        selectedCategories: renderPropInput.selectedCategories,
        tradeDirection: renderPropInput.tradeDirection,
        tradeFlow: renderPropInput.tradeFlow,
      },
    });



    if (transformed.length === 0) {
      output = (
        <ErrorOverlay message={__lexiconText('error.noData')}/>
      );
    } else {
      const numCellsTier = determineTreeMapNumCellsTier(
        queryResult.variables.facet,
        queryResult.variables.productLevel,
      );
      output = (
        <Chart
          highlighted={renderPropInput.highlighted}
          cells={transformed}
          numCellsTier={numCellsTier}
          chartContainerHeight={renderPropInput.height}
          chartContainerWidth={renderPropInput.width}
          onCellClick={renderPropInput.onCellClick}
          onMouseOverCell={renderPropInput.onMouseOverCell}
          onMouseLeaveChart={renderPropInput.onMouseLeaveChart}
        />
      );
    }
  } else {
    output = null;
  }
  return output;
};

const getRenderProp = (input: IRenderPropInput) => (queryResult: Result) => (
  <ChartContainer renderPropInput={input} queryResult={queryResult}/>
);

interface Props {
  input: IRenderPropInput;
  chartKey: string;
  variables: TreeMapInput;
}

const RenderProp = (props: Props) => {
  const {
    input, chartKey, variables,
  } = props;


  /*
  input: Includes settings and values inputted by user, e.g., trade direction, trade flow, selected categories; also includes width and height of element
  chartKey: ID for the chart container
  variables: Query variables, e.g., facet, location, product class, product level

  */

  const chartRenderProp = getRenderProp(input);
  const result = useCombinedQuery(variables);

  return (<React.Fragment key={chartKey}>{chartRenderProp(result as any)}</React.Fragment>);
};

export default RenderProp;
